import type { AxiosResponse } from 'axios';

export function downloadFile({ data, headers = {} }: AxiosResponse, fileName: string) {
  const type = headers['content-type'];
  const fileNameFromApi = headers['content-disposition']?.split('filename=')[1];
  const blob = new Blob([data], { type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileNameFromApi || fileName;
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();
}
