import type { LoaderWrapperProps } from './loader-wrapper.props';

const LoaderWrapper = ({ children, className, isInline }: LoaderWrapperProps) =>
  isInline ? (
    <span className={className}>{children}</span>
  ) : (
    <div className={className}>{children}</div>
  );

export default LoaderWrapper;
