import { useEffect } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Button } from '@devopsafs/react-ui-components';

import Loader from 'src/components/common/loader';
import BrigadeTable from './components/brigade-table';

import contractorsStore from 'src/stores/contractors-store';
import partnershipStore from 'src/stores/partnership-store';

import './partnership-table.scss';

const PartnershipTable = observer(() => {
  const { brigadeListSorted, isLoading, addTeam } = partnershipStore;
  const { sortedContractorsList, isLoading: isContractorsLoading } = contractorsStore;
  const countAvailableContractors = sortedContractorsList.length;
  const isNotAvailableContractors = !isContractorsLoading && !countAvailableContractors;

  useEffect(() => {
    contractorsStore.loadContractors(true);
    partnershipStore.init();
  }, []);

  return (
    <div className="partnership-table">
      <div className="partnership-table__heading">
        <span
          className={classNames(
            'partnership-table__contractors',
            isNotAvailableContractors && 'partnership-table__contractors_not-available'
          )}
        >
          <span className="partnership-table__contractors-text">Contractors available:</span>

          <span className="partnership-table__contractors-text partnership-table__contractors-text_bold">
            {isContractorsLoading ? '-' : countAvailableContractors}
          </span>

          {isNotAvailableContractors && (
            <span className="partnership-table__not-available-contractors">
              You don't have available contractors to create a new Work Team
            </span>
          )}
        </span>

        <Button
          className="partnership-table__button"
          isDisabled={!countAvailableContractors}
          label="Add Work Team"
          theme="outlined"
          onClick={() => addTeam(sortedContractorsList)}
        />
      </div>

      <div className="partnership-table__content scrollbar scrollbar_horizontal">
        {brigadeListSorted.map(({ brigadeName, ...brigade }) => (
          <BrigadeTable key={brigade.id} brigadeName={brigadeName} brigade={brigade} />
        ))}
      </div>
      {(isLoading || isContractorsLoading) && <Loader position="overlay" />}
    </div>
  );
});

export default PartnershipTable;
