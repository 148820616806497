import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Input } from '@devopsafs/react-ui-components';

import ActionButton from 'src/components/common/action-button';
import Tab from 'src/components/ui/tab';
import { ReactComponent as SaveIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as CancelIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';

import ratesStore from 'src/stores/rates-store';

import { AppRoute, CatalogItemType, RatesTypeShow } from 'src/constants';

import './rates-top-panel.scss';

const TABS = [
  { title: RatesTypeShow[CatalogItemType.BUNDLE], link: AppRoute.SETTINGS_RATES_BUNDLE },
  { title: RatesTypeShow[CatalogItemType.SERVICE], link: AppRoute.SETTINGS_RATES_SERVICES },
  { title: RatesTypeShow[CatalogItemType.PRODUCT], link: AppRoute.SETTINGS_RATES_PRODUCTS },
];

const RatesTopPanel = observer(() => {
  const {
    topPanelRate,
    editPanelRate,
    handlePanelRateChange,
    savePanelRate,
    cancelPanelRate,
    isPanelRateProcessing,
  } = ratesStore;
  const baseRate = topPanelRate.value && Number(topPanelRate.value) > 0 ? topPanelRate.value : '-';

  return (
    <div className="rates-top-panel">
      <div className="rates-top-panel__rate-container">
        <p className="rates-top-panel__rate-text">Base Rate, ₪:</p>
        <div
          className={classNames(
            'rates-top-panel__rate-actions',
            topPanelRate.isEditing && 'rates-top-panel__rate-actions_edit'
          )}
        >
          {!topPanelRate.isEditing ? (
            <>
              <span className="rates-top-panel__rate-price">{baseRate}</span>
              <ActionButton icon={<EditIcon />} ariaLabel="Edit" onClick={editPanelRate} />
            </>
          ) : (
            <>
              <Input
                isValid={topPanelRate.isValid}
                placeholder="Base Rate"
                size="mini"
                value={topPanelRate.value}
                onChange={handlePanelRateChange}
              />
              <ActionButton
                isLoading={isPanelRateProcessing}
                ariaLabel="Save"
                color="green"
                icon={<SaveIcon />}
                onClick={savePanelRate}
              />
              <ActionButton
                isLoading={isPanelRateProcessing}
                ariaLabel="Cancel"
                color="red"
                icon={<CancelIcon />}
                onClick={cancelPanelRate}
              />
            </>
          )}
        </div>
      </div>

      <nav className="rates-top-panel__nav">
        <ul className="rates-top-panel__tabs-list">
          {TABS.map((props) => (
            <li key={props.title} className="rates-top-panel__tabs-item">
              <Tab {...props} className="rates-top-panel__tab" activeTextType="regular" />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
});

export default RatesTopPanel;
