import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import MainLayout from 'src/layouts/main-layout';
import ExternalSidebar from 'src/pages/external-sidebar';
import LoginPage from 'src/pages/login-page';
import MainPage from 'src/pages/main-page';
import PrintContractorCalculationPage from 'src/pages/print-contractor-calculation-page';
import AlertBlock from 'src/components/common/alert-block';
import Header from 'src/components/common/header';
import Loader from 'src/components/common/loader';
import PrivateRoute from 'src/components/common/private-route';
import Popups from 'src/components/popups';

import globalAppStore from 'src/stores/global-app-store';

import { AppRoute, PUBLIC_URL } from 'src/constants';
import { SettingsPageRoutes } from 'src/routes';

import 'src/scss/index.scss';

const App = observer(() => {
  const { isConfigCorrect, isLoading, missingConfigVars, token } = globalAppStore;

  if (!isConfigCorrect) {
    return (
      <div className="app app_err">
        <h2 className="app__error">Failed to load config params:</h2>
        <ul className="app__error-list">
          {missingConfigVars.map((item) => (
            <li className="app__error-list-item" key={item.name}>
              <span className="app__error-text">{item.name}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (isLoading) {
    return <Loader position="fixed" />;
  }

  return (
    <div className="app">
      <Router basename={PUBLIC_URL}>
        <Routes>
          {!token && <Route element={<LoginPage />} path={AppRoute.LOGIN} />}
          <Route element={<PrivateRoute isRedirect={!token} />}>
            <Route
              element={<MainLayout children={<MainPage />} header={<Header />} />}
              path={AppRoute.CONTRACTORS_ACCOUNTING}
            />
            {SettingsPageRoutes()}
            <Route
              element={<MainLayout children={<ExternalSidebar />} />}
              path={`${AppRoute.CONTRACTOR_CALCULATION}/:id`}
            />
            <Route
              element={<PrintContractorCalculationPage />}
              path={`${AppRoute.PRINT_CONTRACTOR_CALCULATION}/:id`}
            />
          </Route>
          <Route element={<Navigate replace to={AppRoute.CONTRACTORS_ACCOUNTING} />} path="*" />
        </Routes>
        <Popups />
      </Router>
      <AlertBlock />
    </div>
  );
});

export default App;
