import { makeAutoObservable } from 'mobx';

import popupStore from 'src/stores/popup-store';

import {
  fetchBaseRate,
  fetchBaseRateChange,
  fetchCatalogItemChange,
  fetchCatalogItemCreate,
  fetchCatalogItemDelete,
  fetchCatalogItems,
} from 'src/api';
import {
  CatalogItemType,
  ErrorText,
  InputMaxLength,
  InputType,
  MAX_BASE_RATE_VALUE,
  PopupName,
  Regex,
} from 'src/constants';
import {
  getDeepObjectDiff,
  getIsManHoursValid,
  getIsRateNameValid,
  getMainCurrency,
  getSubunitCurrency,
  trimSpaces,
} from 'src/utils';
import type { ICatalogItem, IForm } from 'src/interfaces';

const NEW_RATES_ID = 'new';

const initialFields = {
  name: {
    isValid: true,
    type: InputType.NAME,
    value: '',
  },
  manHours: {
    isValid: true,
    type: InputType.RATE,
    value: '',
  },
};

type TRateForm = IForm<keyof typeof initialFields>;

type TRateField = keyof TRateForm;

interface IPanelRate {
  isEditing: boolean;
  value: string;
  prevValue: string;
  isValid: boolean;
}

class RatesStore {
  async init(type: CatalogItemType) {
    this.cancelPanelRate();
    this.clearStore();
    await Promise.all([this.loadBaseRate(), this.loadCatalog(type)]);
  }

  isLoading: boolean = false;
  ratesList: ICatalogItem[] = [];
  forms: Record<string, TRateForm> = {
    [NEW_RATES_ID]: initialFields,
  };
  processingRateId: string = '';
  isPanelRateProcessing: boolean = false;
  ratesType: CatalogItemType;

  panelRate: IPanelRate = {
    isEditing: false,
    isValid: true,
    value: '',
    prevValue: '',
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setRateType(type: CatalogItemType) {
    this.ratesType = type;
  }

  setProcessingRateId(id: string) {
    this.processingRateId = id;
  }

  setRatesList(rates: ICatalogItem[]) {
    this.ratesList = rates;
  }

  setForm(id: string, formData: TRateForm = initialFields) {
    this.forms[id] = formData;
  }

  setFieldValid(id: string, fieldName: TRateField, isValid: boolean) {
    const form = this.getForm(id);
    this.setForm(id, { ...form, [fieldName]: { ...form[fieldName], isValid } });
  }

  setFieldValue(id: string, fieldName: TRateField, value: string) {
    const form = this.getForm(id);

    if (fieldName === 'manHours' && value && !Regex.MAN_HOURS.test(value)) {
      return;
    }

    const formattedValue = fieldName === 'manHours' ? value.replace(',', '.') : value;

    this.setForm(id, {
      ...form,
      [fieldName]: { ...form[fieldName], isValid: true, value: formattedValue },
    });
  }

  setPanelRate(rate: IPanelRate) {
    this.panelRate = rate;
  }

  setIsPanelRateProcessing(isProcessing: boolean) {
    this.isPanelRateProcessing = isProcessing;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  getForm(id: string) {
    return this.forms[id] ?? initialFields;
  }

  getRate(id: string) {
    return this.ratesList.find((rate) => rate.id === id);
  }

  getInputLabel(fieldName: TRateField) {
    const labels = {
      name: 'Name',
      manHours: 'Man Hours',
    };
    return labels[fieldName] ?? null;
  }

  getInputProps(id: string, fieldName: TRateField) {
    const { type, ...publicProperties } = this.getForm(id)[fieldName];
    return {
      ...publicProperties,
      ...(type === InputType.NAME && { maxLength: InputMaxLength.TEXT }),
      isLabelFloating: false,
      label: this.getInputLabel(fieldName),
      size: 'mini' as const,
      onChange: (value: string) => this.setFieldValue(id, fieldName, value),
    };
  }

  getNewRateInputProps(fieldName: TRateField) {
    return this.getInputProps(NEW_RATES_ID, fieldName);
  }

  get topPanelRate() {
    return this.panelRate;
  }

  handlePanelRateChange(value: string) {
    const countedValue =
      Number(value) > MAX_BASE_RATE_VALUE ? MAX_BASE_RATE_VALUE.toString() : value;
    if (!value || Regex.BASE_RATE.test(countedValue)) {
      this.setPanelRate({
        ...this.panelRate,
        isValid: true,
        value: (parseInt(countedValue, 10) || 0).toString(),
      });
    }
  }

  checkIsEditingForm(id: string) {
    return id in this.forms;
  }

  checkIsInputValid(id: string, fieldName: TRateField) {
    const { type, value } = this.getForm(id)[fieldName];
    switch (type) {
      case InputType.NAME:
        return getIsRateNameValid(value);
      case InputType.RATE:
        return getIsManHoursValid(value);
      default:
        return true;
    }
  }

  checkIsFormValid(id: string) {
    return Object.keys(this.getForm(id)).reduce((isFormValid, field) => {
      const isValid = this.checkIsInputValid(id, field);
      if (!isValid) {
        isFormValid = false;
        this.setFieldValid(id, field, false);
      }
      return isFormValid;
    }, true);
  }

  checkPanelRateIsValid() {
    const isValid = !!this.topPanelRate.value && Number(this.topPanelRate.value) > 0;
    if (!isValid) {
      this.setPanelRate({ ...this.panelRate, isValid });
    }
    return isValid;
  }

  editForm(id: string) {
    const rate = this.getRate(id);
    this.setForm(
      id,
      Object.keys(initialFields).reduce<TRateForm>(
        (form, field) => ({
          ...form,
          [field]: { ...form[field], value: rate?.[field].toString() ?? '' },
        }),
        initialFields
      )
    );
  }

  removeForm(rateId: string) {
    if (rateId === NEW_RATES_ID) {
      this.setForm(NEW_RATES_ID);
    } else {
      delete this.forms[rateId];
    }
  }

  deleteEntry(id: string) {
    popupStore.showPopup(PopupName.CONFIRM, {
      mainActionLabel: 'Delete',
      secondaryActionLabel: 'Cancel',
      text: 'Are you sure you want to delete this Entry with all data?',
      title: 'Delete Entry?',
      mainActionHandler: async (onCloseButtonClick?: () => void) => {
        this.setProcessingRateId(id);

        const { isSuccess, error } = await fetchCatalogItemDelete(id);

        if (isSuccess) {
          await this.loadCatalog(this.ratesType);
          onCloseButtonClick?.();
        } else {
          popupStore.showPopup(PopupName.WARN, {
            title: ErrorText.REQUEST_FAILURE,
            text: error || ErrorText.DEFAULT,
          });
        }

        this.setProcessingRateId('');
      },
    });
  }

  editPanelRate() {
    const newPanelRate = {
      ...this.panelRate,
      isEditing: true,
      prevValue: this.panelRate.value,
    };
    this.setPanelRate(newPanelRate);
  }

  cancelPanelRate() {
    const newPanelRate = {
      ...this.panelRate,
      isValid: true,
      isEditing: false,
      value: this.panelRate.prevValue,
    };
    this.setPanelRate(newPanelRate);
  }

  clearStore() {
    Object.keys(this.forms).forEach((id) => {
      this.removeForm(id);
    });
  }

  async handleChangeRateClick(id: string) {
    const rate = this.getRate(id);

    if (this.checkIsFormValid(id) && rate) {
      const { name, manHours } = this.getForm(id);

      const changedCatalogItem = trimSpaces({
        name: name.value,
        man_hours: Number(manHours.value),
      });

      const prevCatalogItem = trimSpaces({
        name: rate.name,
        man_hours: rate.manHours,
      });

      const catalogItemDiff = getDeepObjectDiff(prevCatalogItem, changedCatalogItem);

      if (!Object.keys(catalogItemDiff).length) {
        return this.removeForm(id);
      }

      this.setProcessingRateId(id);

      const { data, error, isSuccess } = await fetchCatalogItemChange(id, catalogItemDiff);

      if (isSuccess) {
        const updatedCatalogItemIndex = this.ratesList.findIndex((rate) => rate.id === id);
        this.setRatesList([
          ...this.ratesList.slice(0, updatedCatalogItemIndex),
          data,
          ...this.ratesList.slice(updatedCatalogItemIndex + 1),
        ]);

        this.removeForm(id);
      } else {
        popupStore.showPopup(PopupName.WARN, {
          title: ErrorText.REQUEST_FAILURE,
          text: error || ErrorText.DEFAULT,
        });
      }

      this.setProcessingRateId('');
    }
  }

  async handleAddRateClick() {
    if (this.checkIsFormValid(NEW_RATES_ID)) {
      const { name, manHours } = this.getForm(NEW_RATES_ID);
      this.setIsLoading(true);

      const { isSuccess, error } = await fetchCatalogItemCreate(
        trimSpaces({
          type: this.ratesType,
          man_hours: Number(manHours.value),
          name: name.value,
        })
      );

      if (isSuccess) {
        this.removeForm(NEW_RATES_ID);
        await this.loadCatalog(this.ratesType);
      } else {
        popupStore.showPopup(PopupName.WARN, {
          title: ErrorText.REQUEST_FAILURE,
          text: error || ErrorText.DEFAULT,
        });
      }

      this.setIsLoading(false);
    }
  }

  async loadCatalog(type?: CatalogItemType) {
    this.setIsLoading(true);
    const catalogItems = await fetchCatalogItems(type);
    this.setRatesList(catalogItems);

    this.setIsLoading(false);
  }

  async savePanelRate() {
    if (this.checkPanelRateIsValid()) {
      const isEqual = this.panelRate.value === this.panelRate.prevValue;

      if (isEqual) {
        return this.cancelPanelRate();
      }

      this.setIsPanelRateProcessing(true);
      const { data, isSuccess, error } = await fetchBaseRateChange({
        value: getSubunitCurrency(Number(this.panelRate.value)),
      });

      if (isSuccess) {
        const value = getMainCurrency(data?.value || 0).toString();
        const newPanelRate = {
          isValid: true,
          value: value,
          isEditing: false,
          prevValue: value,
        };
        this.setPanelRate(newPanelRate);
        await this.loadCatalog(this.ratesType);
      } else {
        popupStore.showPopup(PopupName.WARN, {
          title: ErrorText.REQUEST_FAILURE,
          text: error || ErrorText.DEFAULT,
        });
      }

      this.setIsPanelRateProcessing(false);
    }
  }

  async loadBaseRate() {
    this.setIsPanelRateProcessing(true);

    const baseRate = await fetchBaseRate();
    const value = getMainCurrency(baseRate?.value || 0).toString();
    this.setPanelRate({
      ...this.panelRate,
      value,
      prevValue: value,
    });

    this.setIsPanelRateProcessing(false);
  }
}

export default new RatesStore();
