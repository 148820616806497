import { doPost, doPut } from './axios-config';

import { mapApiToDetailedCalculation } from 'src/adapters';
import { ApiRoute, OrderIncludedStatus } from 'src/constants';
import type { IApiDetailedCalculation, IDetailedCalculation, TApiResponse } from 'src/interfaces';

export async function fetchOrderItemCreate(
  calculationId: string,
  orderId: string,
  itemId: string
): Promise<TApiResponse<IDetailedCalculation>> {
  try {
    const { data } = await doPost<IApiDetailedCalculation>(
      ApiRoute.ORDER_ITEMS(calculationId, orderId),
      { item: itemId }
    );

    return { isSuccess: true, data: mapApiToDetailedCalculation(data) };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchOrderChangeStatus(
  calculationId: string,
  orderId: string,
  isIncluded: boolean
): Promise<TApiResponse<IDetailedCalculation>> {
  try {
    const { data } = await doPut<IApiDetailedCalculation>(
      ApiRoute.ORDER_STATUS(calculationId, orderId),
      { value: isIncluded ? OrderIncludedStatus.INCLUDED : OrderIncludedStatus.EXCLUDED }
    );

    return { isSuccess: true, data: mapApiToDetailedCalculation(data) };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchOrderItemChangeStatus(
  calculationId: string,
  orderItemId: string,
  isIncluded: boolean
): Promise<TApiResponse<IDetailedCalculation>> {
  try {
    const { data } = await doPut<IApiDetailedCalculation>(
      ApiRoute.ORDER_ITEM_STATUS(calculationId, orderItemId),
      { value: isIncluded ? OrderIncludedStatus.INCLUDED : OrderIncludedStatus.EXCLUDED }
    );

    return { isSuccess: true, data: mapApiToDetailedCalculation(data) };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
