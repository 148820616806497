import React, { ReactElement } from 'react';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';

import type { AlertType } from 'src/constants';
import type { IAlertProps } from './alert.props';

import './alert.scss';

export function Alert({ id, desc, timeout, title, type, withIcon, onClose }: IAlertProps) {
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleClose = React.useCallback(() => onClose(id), [id, onClose]);

  React.useEffect(() => {
    if (timeout) {
      timeoutRef.current = setTimeout(handleClose, timeout);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeout, handleClose]);

  const classes = classNames('alert', `alert_${type}`, {
    alert_icon: withIcon,
  });

  return (
    <div className={classes}>
      {withIcon && getTypeDependedIcon(type)}
      <div className="alert__title">{title}</div>
      <div className="alert__desc">{desc}</div>
      <CloseIcon className="alert__close-icon" onClick={handleClose} />
    </div>
  );
}

function getTypeDependedIcon(type: AlertType) {
  const cfg: Record<AlertType, ReactElement> = {
    error: <ErrorIcon className="alert__type-icon" />,
    info: <InfoIcon className="alert__type-icon" />,
    warning: <WarningIcon className="alert__type-icon" />,
  };

  return cfg[type];
}
