import { mapApiToPerson } from 'src/adapters/map-api-to-person';
import type {
  IApiOrder,
  IApiOrderItem,
  IApiReworkParentCalculation,
  IOrder,
  IOrderItem,
  IReworkParentCalculation,
} from 'src/interfaces';

export const mapApiToOrderItem = ({
  included_in_calculation,
  man_hours,
  is_reworked,
  ...rest
}: IApiOrderItem): IOrderItem => {
  return {
    ...rest,
    includedInCalculation: included_in_calculation,
    isReworked: is_reworked,
    manHours: man_hours,
  };
};

const mapApiToReworkParentCalculation = ({
  contractor,
  ...rest
}: IApiReworkParentCalculation): IReworkParentCalculation => {
  return {
    ...rest,
    contractor: mapApiToPerson(contractor),
  };
};

export const mapApiToOrder = ({
  cemetery,
  deceased,
  crm_lead_id,
  crm_url,
  included_in_calculation,
  is_rework_parent,
  is_rework_child,
  rework_parent_calculations,
  items,
  ...rest
}: IApiOrder): IOrder => {
  return {
    ...rest,
    cemetery: cemetery || 'N/A',
    crmLeadId: crm_lead_id,
    crmUrl: crm_url,
    deceased: deceased || 'N/A',
    includedInCalculation: included_in_calculation,
    isReworkChild: is_rework_child ?? false,
    isReworkParent: is_rework_parent ?? false,
    reworkParentCalculations: rework_parent_calculations
      ? rework_parent_calculations.map(mapApiToReworkParentCalculation)
      : [],
    items: items.map(mapApiToOrderItem),
  };
};
