import { CURRENCY_CENTS_COUNT, CURRENCY_CODE } from 'src/constants';

const LocaleString = {
  he: 'he-IL',
};

export function formatMoney(sum: number, withCurrencyCode = false) {
  let value = '0';

  if (sum) {
    const moneySum = sum / CURRENCY_CENTS_COUNT;

    const isFractional = moneySum % 1 !== 0;

    const options = isFractional
      ? {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
        }
      : { maximumFractionDigits: 0 };

    value = moneySum.toLocaleString(LocaleString.he, options);
  }

  return withCurrencyCode ? `${CURRENCY_CODE}${value}` : value;
}

export const getMainCurrency = (cents: number) => Math.floor(cents / CURRENCY_CENTS_COUNT) || 0;

export const getSubunitCurrency = (mainCurrency: number) =>
  Math.floor(mainCurrency * CURRENCY_CENTS_COUNT) || 0;
