import classNames from 'classnames';

import Loader from 'src/components/common/loader';
import IconButton from 'src/components/ui/icon-button';

import type { ActionButtonProps } from './action-button.props';

import './action-button.scss';

const ActionButton = ({
  className,
  ariaLabel,
  color,
  icon,
  isDisabled,
  isLoading,
  onClick,
}: ActionButtonProps) => {
  const isButtonDisabled = isDisabled || isLoading;
  return (
    <IconButton
      className={classNames(
        'action-button',
        color && !isButtonDisabled && `action-button_${color}`,
        className
      )}
      ariaLabel={ariaLabel}
      icon={isLoading ? <Loader isInline size="small" /> : icon}
      isDisabled={isButtonDisabled}
      theme="square"
      onClick={onClick}
    />
  );
};

export default ActionButton;
