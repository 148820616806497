import { SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import TableButtonDropdown from './components/table-button-dropdown';
import { ReactComponent as BlockIcon } from 'src/assets/icons/block.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as DownArrowIcon } from 'src/assets/icons/chevron-down.svg';
import { ReactComponent as TimeIcon } from 'src/assets/icons/time.svg';

import useDropdownPosition from 'src/hooks/use-dropdown-position';
import useKeyUp from 'src/hooks/use-key-up';
import useKeyUpOutside from 'src/hooks/use-key-up-outside';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import {
  CalculationStatus,
  ContractorStatus,
  Key,
  TableButtonActionLabel,
  TableButtonStatusLabel,
} from 'src/constants';
import type { ITableButtonStatus } from 'src/interfaces';
import type { TableButtonProps } from './table-button.props';

import './table-button.scss';

const DROPDOWN_HEIGHT = 128 - 32; // Dropdown with padding - button height

const getStatusIcon = (status: ITableButtonStatus) => {
  switch (status) {
    case ContractorStatus.ACTIVE:
    case CalculationStatus.APPROVED:
      return <CheckIcon />;

    case ContractorStatus.BLOCKED:
    case CalculationStatus.DECLINED:
      return <BlockIcon />;

    case CalculationStatus.WAITING:
      return <TimeIcon />;
  }
};

const TableButton = <T extends ContractorStatus | CalculationStatus>({
  isBadge,
  isDisabled,
  status,
  onStatusChange,
}: TableButtonProps<T>) => {
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const tableButtonRef = useRef<HTMLDivElement>(null);
  const dropdownPosition = useDropdownPosition(tableButtonRef, '.table', DROPDOWN_HEIGHT);
  const buttonLabel = TableButtonStatusLabel[status];
  const statusIcon = getStatusIcon(status);

  const statusActionsProps = useMemo(() => {
    const getChangeStatusHandler = (status: ITableButtonStatus) => () => {
      onStatusChange?.(status as T);
      setIsShowDropdown(false);
    };

    switch (status) {
      case CalculationStatus.WAITING:
        return {
          mainLabel: TableButtonActionLabel[CalculationStatus.APPROVED],
          secondLabel: TableButtonActionLabel[CalculationStatus.DECLINED],
          onMainAction: getChangeStatusHandler(CalculationStatus.APPROVED),
          onSecondAction: getChangeStatusHandler(CalculationStatus.DECLINED),
        };

      case ContractorStatus.ACTIVE:
        return {
          secondLabel: TableButtonActionLabel[ContractorStatus.BLOCKED],
          onSecondAction: getChangeStatusHandler(ContractorStatus.BLOCKED),
        };

      case ContractorStatus.BLOCKED:
        return {
          mainLabel: TableButtonActionLabel[ContractorStatus.ACTIVE],
          onMainAction: getChangeStatusHandler(ContractorStatus.ACTIVE),
        };
    }
  }, [onStatusChange, status]);

  const closeDropdown = useCallback(() => setIsShowDropdown(false), []);

  useOnClickOutside(tableButtonRef, closeDropdown, !isShowDropdown);
  useKeyUpOutside(Key.ENTER, tableButtonRef, closeDropdown, !isShowDropdown);
  useKeyUp(Key.ESCAPE, closeDropdown, tableButtonRef, !isShowDropdown);

  const toggleDropdown = useCallback((evt: SyntheticEvent) => {
    evt.stopPropagation();
    setIsShowDropdown((prevState) => !prevState);
  }, []);

  return (
    <div
      className={classNames(
        'table-button',
        `table-button_${status}`,
        isDisabled && !isBadge && 'table-button_disabled',
        isBadge && 'table-button_badge'
      )}
      ref={tableButtonRef}
    >
      {isBadge || !statusActionsProps ? (
        <div className="table-button__badge">
          <span className="table-button__icon">{statusIcon}</span>
          <span className="table-button__label">{buttonLabel}</span>
        </div>
      ) : (
        <>
          <button
            className={classNames(
              'table-button__button',
              isShowDropdown && 'table-button__button_toggler-open'
            )}
            aria-label="Toggle status"
            disabled={isDisabled}
            onClick={toggleDropdown}
          >
            <span className="table-button__icon">{statusIcon}</span>
            <span className="table-button__label">{buttonLabel}</span>
            <DownArrowIcon
              className={classNames(
                'table-button__toggler',
                isShowDropdown && 'table-button__toggler_open'
              )}
            />
          </button>
          {isShowDropdown && (
            <TableButtonDropdown
              isDisabled={isDisabled}
              position={dropdownPosition}
              {...statusActionsProps}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TableButton;
