import React from 'react';
import classNames from 'classnames';

import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as CalendarIcon } from 'src/assets/icons/calendar.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';

import type { DatePickerIconProps } from './date-picker-icon.props';

import './date-picker-icon.scss';

function DatePickerIcon({ className, isChecked, isDisabled, toggleCalendar }: DatePickerIconProps) {
  return (
    <IconButton
      className={classNames('date-picker-icon', className)}
      ariaLabel="Toggle calendar"
      icon={
        isChecked ? (
          <CheckIcon className="date-picker-icon__check-icon" />
        ) : (
          <CalendarIcon className="date-picker-icon__calendar-icon" />
        )
      }
      isDisabled={isChecked || isDisabled}
      theme="plain"
      onClick={toggleCalendar}
    />
  );
}

export default DatePickerIcon;
