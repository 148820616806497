import React from 'react';
import classNames from 'classnames';

import HeaderButton from 'src/components/ui/header-button';

import type { TableHeaderProps } from './table-header.props';

import './table-header.scss';

function TableHeader({ items, tableType, onSortByColumn }: TableHeaderProps) {
  return (
    <thead className={classNames('table-header', `table-header_${tableType}`)}>
      <tr className="table-header__row">
        {items.map(({ className, id, isSorting, sortDirection, value }) => (
          <td className={classNames('table-header__col', className)} key={id}>
            {isSorting ? (
              <HeaderButton
                label={value}
                sortDirection={sortDirection}
                onClick={onSortByColumn?.(id)}
              />
            ) : (
              value
            )}
          </td>
        ))}
      </tr>
    </thead>
  );
}

export default TableHeader;
