import React from 'react';
import classNames from 'classnames';

import { ReactComponent as TriangleIcon } from 'src/assets/icons/triangle.svg';

import { SortDirection } from 'src/constants';
import type { HeaderButtonProps } from './header-button.props';

import './header-button.scss';

function HeaderButton({ label, sortDirection, onClick }: HeaderButtonProps) {
  return (
    <button
      className={classNames('header-button', sortDirection && 'header-button_arrow')}
      onClick={onClick}
    >
      <span className="header-button__label">{label}</span>
      {sortDirection && (
        <span
          className={classNames(
            'header-button__arrow-icon',
            sortDirection === SortDirection.ASC && 'header-button__arrow-icon_sort-asc'
          )}
        >
          <TriangleIcon />
        </span>
      )}
    </button>
  );
}

export default HeaderButton;
