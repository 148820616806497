import { makeAutoObservable, reaction } from 'mobx';
import type { TextareaProps } from '@devopsafs/react-ui-components';

import calculationsStore from 'src/stores/calculations-store';
import popupStore from 'src/stores/popup-store';
import sidebarStore from 'src/stores/sidebar-store';

import {
  fetchCalculation,
  fetchCalculationChangeBonus,
  fetchCalculationChangeFine,
  fetchCalculationChangeStatus,
  fetchOrderChangeStatus,
  fetchOrderItemChangeStatus,
  fetchOrderItemCreate,
} from 'src/api';
import {
  CalculationStatus,
  ChangeStatusPopupText,
  ErrorText,
  InputMaxLength,
  PopupName,
} from 'src/constants';
import type { IDetailedCalculation, TApiResponse } from 'src/interfaces';

class CalculationDetailsStore {
  calculation: IDetailedCalculation | null = null;
  isLoading = false;
  popupComment = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => sidebarStore.dataId,
      (id) => {
        if (id) {
          this.openCalculation(id);
        } else {
          this.closeCalculation();
        }
      },
      { fireImmediately: true }
    );
  }

  setCalculation(calculation: IDetailedCalculation | null) {
    this.calculation = calculation;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setPopupComment(popupComment: string) {
    this.popupComment = popupComment;
  }

  openCalculation(id: string) {
    void this.loadCalculation(id);
  }

  closeCalculation() {
    this.clearStore();
  }

  setProcessing(calculationId: string, isProcessing: boolean) {
    if (isProcessing) {
      calculationsStore.addProcessingCalculation(calculationId);
    } else {
      calculationsStore.removeProcessingCalculation(calculationId);
    }
  }

  clearStore() {
    this.setIsLoading(false);
    this.setCalculation(null);
  }

  get calculationComment() {
    return this.calculation?.comments[0]?.text ?? '';
  }

  get isProcessing() {
    const calculationId = this.calculation?.id;
    return !!calculationId && calculationsStore.processingCalculationsIds.includes(calculationId);
  }

  handleBonusChange(value: number) {
    return this.updateCalculationByApiRequest((calculation) =>
      fetchCalculationChangeBonus(calculation.id, value)
    );
  }

  handleFineChange(value: number) {
    return this.updateCalculationByApiRequest((calculation) =>
      fetchCalculationChangeFine(calculation.id, value)
    );
  }

  handleAddDealItem(orderId: string, orderItemId: string) {
    return this.updateCalculationByApiRequest((calculation) =>
      fetchOrderItemCreate(calculation.id, orderId, orderItemId)
    );
  }

  handleRemoveDeal(orderId: string) {
    this.confirmAction(
      {
        mainActionLabel: 'Delete',
        text: 'Are You sure you want to delete this Deal with all Bundles, Products & Services?',
        title: 'Delete Deal?',
      },
      (onClosePopup) =>
        this.updateCalculationByApiRequest(
          (calculation) => fetchOrderChangeStatus(calculation.id, orderId, false),
          onClosePopup
        )
    );
  }

  handleRemoveDealItem(orderItemId: string) {
    this.confirmAction(
      {
        mainActionLabel: 'Delete',
        text: 'Are you sure you want to delete this Entry with all data?',
        title: 'Delete Entry?',
      },
      (onClosePopup) =>
        this.updateCalculationByApiRequest(
          (calculation) => fetchOrderItemChangeStatus(calculation.id, orderItemId, false),
          onClosePopup
        )
    );
  }

  handleRestoreDeal(orderId: string) {
    this.confirmAction(
      {
        mainActionLabel: 'Restore',
        text: 'Are you sure you want to restore this Deal with all data?',
        title: 'Restore Deal?',
      },
      (onClosePopup) =>
        this.updateCalculationByApiRequest(
          (calculation) => fetchOrderChangeStatus(calculation.id, orderId, true),
          onClosePopup
        )
    );
  }

  handleRestoreDealItem(orderItemId: string) {
    this.confirmAction(
      {
        mainActionLabel: 'Restore',
        text: 'Are you sure you want to restore this Entry with all data?',
        title: 'Restore Entry?',
      },
      (onClosePopup) =>
        this.updateCalculationByApiRequest(
          (calculation) => fetchOrderItemChangeStatus(calculation.id, orderItemId, true),
          onClosePopup
        )
    );
  }

  confirmAction(
    popupProps: {
      mainActionLabel: string;
      text: string;
      textareaProps?: TextareaProps;
      title: string;
    },
    callback: (onClosePopup: () => void) => Promise<void>
  ) {
    popupStore.showPopup(PopupName.CONFIRM, {
      ...popupProps,
      secondaryActionLabel: 'Cancel',
      mainActionHandler: callback,
    });
  }

  async changeCalculationStatus(
    status: CalculationStatus,
    calculationId?: string,
    comment?: string
  ) {
    const id = calculationId || this.calculation?.id;
    if (!id) {
      return;
    }

    const isAllowComment = status !== CalculationStatus.WAITING;
    const { buttonLabel: mainActionLabel, text, title } = ChangeStatusPopupText[status];
    this.confirmAction(
      {
        mainActionLabel,
        text,
        title,
        ...(isAllowComment && {
          textareaProps: {
            hasCounter: true,
            maxLength: InputMaxLength.COMMENT,
            placeholder: 'Message for contractor',
            rows: 6,
            value: comment ?? this.calculationComment,
            onChange: this.setPopupComment,
          },
        }),
      },
      async (onClosePopup) => {
        this.setProcessing(id, true);

        const { data, error, isSuccess } = await fetchCalculationChangeStatus(id, status, {
          prevComment: comment ?? this.calculationComment,
          newComment: this.popupComment,
        });
        if (isSuccess) {
          if (data.id === id) {
            this.setCalculation(data);
          }
          onClosePopup();
        } else {
          popupStore.showPopup(PopupName.WARN, {
            title: ErrorText.REQUEST_FAILURE,
            text: error || ErrorText.DEFAULT,
          });
        }

        this.setProcessing(id, false);
      }
    );
  }

  async updateCalculationByApiRequest(
    callback: (calculation: IDetailedCalculation) => Promise<TApiResponse<IDetailedCalculation>>,
    onClosePopup?: () => void
  ) {
    const calculation = this.calculation;
    if (!calculation) {
      return;
    }
    this.setProcessing(calculation.id, true);

    const { data, error, isSuccess } = await callback(calculation);
    if (isSuccess) {
      if (data.id === calculation.id) {
        this.setCalculation(data);
      }
      onClosePopup?.();
    } else {
      popupStore.showPopup(PopupName.WARN, {
        title: ErrorText.REQUEST_FAILURE,
        text: error || ErrorText.DEFAULT,
      });
    }

    this.setProcessing(calculation.id, false);
  }

  async loadCalculation(id: string) {
    this.setIsLoading(true);

    const calculation = await fetchCalculation(id);
    if (calculation) {
      this.setCalculation(calculation);
    }

    this.setIsLoading(false);
  }
}

export default new CalculationDetailsStore();
