import { makeAutoObservable } from 'mobx';

import type { IAlert } from 'src/interfaces';

class GlobalAlertStore {
  alerts: IAlert[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setAlerts(alerts: IAlert[]) {
    this.alerts = alerts;
  }

  addAlert(alert: IAlert) {
    this.setAlerts([...this.alerts, alert]);
  }

  deleteAlert(id: string) {
    this.setAlerts(this.alerts.filter((alert) => alert.id !== id));
  }
}

export default new GlobalAlertStore();
