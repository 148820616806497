import React, { SyntheticEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import type { ButtonProps } from './icon-button.props';

import './icon-button.scss';

const IconButton = ({
  className,
  ariaLabel,
  href = '',
  icon,
  isDisabled,
  tabIndex = 0,
  tag = 'button',
  target,
  theme = 'halo',
  type = 'button',
  onClick,
  onMouseDown,
}: ButtonProps) => {
  const buttonClasses = classNames(
    'icon-button',
    className,
    `icon-button_${theme}`,
    isDisabled && 'icon-button_disabled'
  );

  const handleClick = useCallback(
    (evt: SyntheticEvent) => {
      if (tag === 'button') {
        evt.preventDefault();
        evt.stopPropagation();
      }

      if (typeof onClick === 'function') {
        onClick(evt);
      }
    },
    [tag, onClick]
  );

  switch (tag) {
    case 'button':
      return (
        <button
          className={buttonClasses}
          aria-label={ariaLabel}
          disabled={isDisabled}
          tabIndex={tabIndex}
          type={type}
          onClick={handleClick}
          onMouseDown={onMouseDown}
        >
          {icon}
        </button>
      );
    case 'link':
      return (
        <Link
          className={buttonClasses}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
          target={target || '_self'}
          to={href}
          onClick={handleClick}
        >
          {icon}
        </Link>
      );
    case 'a':
      return (
        <a
          className={buttonClasses}
          aria-label={ariaLabel}
          href={href}
          rel="noopener noreferrer"
          tabIndex={tabIndex}
          target={target || '_blank'}
          onClick={handleClick}
        >
          {icon}
        </a>
      );
    default:
      return null;
  }
};

export default IconButton;
