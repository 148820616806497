import React from 'react';
import classNames from 'classnames';

import type { TextErrorProps } from './text-error.props';

import './text-error.scss';

function TextError({ className, textError }: TextErrorProps) {
  const classes = classNames('text-error', className);

  return <span className={classes}>{textError}</span>;
}

export default TextError;
