import type { ITableHeaderCell } from 'src/interfaces';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TableType {
  BRIGADE = 'brigade',
  CONTRACTORS = 'contractors',
  MAIN = 'main',
  RATES = 'rates',
}

export enum TableColumn {
  ACTION = 'action',
  ACTIONS = 'actions',
  ADDONS_COSTS = 'addons-costs',
  ADDONS_COUNT = 'addons-count',
  BONUS = 'bonus',
  BUNDLE = 'bundle',
  BUNDLE_COSTS = 'bundle-costs',
  BUNDLE_COUNT = 'bundle-count',
  CONTRACTOR = 'contractor',
  EDIT = 'edit',
  EMAIL = 'email',
  FINE = 'fine',
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  MAN_HOURS = 'man-hours',
  NAME = 'name',
  PHONE = 'phone',
  PRICE = 'price',
  PRODUCTS = 'products',
  SERVICES = 'services',
  STATUS = 'status',
  TOTAL = 'total',
}

export const TABLE_MAIN_COLUMNS: ITableHeaderCell[] = [
  { id: TableColumn.CONTRACTOR, value: 'Contractor' },
  { id: TableColumn.BUNDLE_COUNT, value: 'Bundle count' },
  { id: TableColumn.BUNDLE_COSTS, value: 'Bundle costs, ₪' },
  { id: TableColumn.ADDONS_COUNT, value: 'Addons count' },
  { id: TableColumn.ADDONS_COSTS, value: 'Addons costs, ₪' },
  { id: TableColumn.BONUS, value: 'Bonus, ₪' },
  { id: TableColumn.FINE, value: 'Fine, ₪' },
  { id: TableColumn.TOTAL, value: 'Total, ₪' },
  { id: TableColumn.STATUS, value: 'Status / Actions' },
];

export const TABLE_CONTRACTORS_COLUMNS: ITableHeaderCell[] = [
  { id: TableColumn.FIRSTNAME, value: 'Firstname' },
  { id: TableColumn.LASTNAME, value: 'Lastname' },
  { id: TableColumn.PHONE, value: 'Phone' },
  { id: TableColumn.EMAIL, value: 'Email' },
  { id: TableColumn.EDIT, value: 'Edit' },
  { id: TableColumn.STATUS, value: 'Status' },
];

export const TABLE_BRIGADE_COLUMNS: ITableHeaderCell[] = [
  { id: TableColumn.CONTRACTOR, value: 'Contractor' },
  { id: TableColumn.BUNDLE, value: 'Bundle' },
  { id: TableColumn.PRODUCTS, value: 'Products' },
  { id: TableColumn.SERVICES, value: 'Services' },
  { id: TableColumn.ACTION, value: 'Action' },
];

export const TABLE_RATES_COLUMNS: ITableHeaderCell[] = [
  { id: TableColumn.NAME, value: 'Name' },
  { id: TableColumn.MAN_HOURS, value: 'Man Hours' },
  { id: TableColumn.PRICE, value: 'Price, ₪' },
  { id: TableColumn.ACTIONS, value: 'Actions' },
];
