import type { KeyboardEvent } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import TableCol from '../table-col';

import { Key } from 'src/constants';
import { getTableRowId } from 'src/utils';
import type { TableRowProps } from './table-row.props';

import './table-row.scss';

const TableRow = observer(
  ({ row: { className, id, items, onClick }, tableType }: TableRowProps) => {
    const handleTableRowClick = () => {
      onClick?.(id);
    };

    const handleKeyDown = (evt: KeyboardEvent) => {
      if (evt.target === evt.currentTarget && evt.key === Key.ENTER) {
        handleTableRowClick();
      }
    };

    return (
      <tr
        className={classNames(
          'table-row',
          `table-row_${tableType}`,
          onClick && 'table-row_clickable',
          className
        )}
        id={getTableRowId(id)}
        tabIndex={onClick ? 0 : -1}
        onClick={handleTableRowClick}
        onKeyDown={handleKeyDown}
      >
        {items.map(({ className, childComponent, isBold, isFade, text }, idx) => (
          <TableCol className={className} key={idx} isBold={isBold} isFade={isFade} text={text}>
            {childComponent}
          </TableCol>
        ))}
      </tr>
    );
  }
);

export default TableRow;
