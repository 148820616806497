import Status from 'src/components/common/status';
import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as IconCancel } from 'src/assets/icons/cross.svg';
import { ReactComponent as IconNewWindow } from 'src/assets/icons/new-window.svg';
import { ReactComponent as IconPrint } from 'src/assets/icons/print.svg';

import { AppRoute, DateFormat } from 'src/constants';
import { formatDate } from 'src/utils';
import type { SidebarHeaderProps } from './sidebar-header.props';

import './sidebar-header.scss';

const SidebarHeader = ({
  contractorCalculationId,
  date,
  isExternal,
  isPrintable,
  status,
  title,
  onCancelBtnClick,
}: SidebarHeaderProps) => (
  <div className="sidebar-header">
    <div className="sidebar-header__content sidebar-header__content_main">
      {!isExternal && (
        <IconButton
          ariaLabel="Hide Sidebar"
          icon={<IconCancel />}
          theme="plain"
          onClick={onCancelBtnClick}
        />
      )}

      <h2 className="sidebar-header__title">
        <span className="sidebar-header__title-name">{title}</span>
        <span className="sidebar-header__title-date">{formatDate(date, DateFormat.FULL)}</span>
      </h2>
    </div>

    <div className="sidebar-header__content sidebar-header__content_info">
      <Status isPrintable={isPrintable} type={status} />
      {!isPrintable && (
        <IconButton
          ariaLabel="Print contractor's calculation"
          href={`${AppRoute.PRINT_CONTRACTOR_CALCULATION}/${contractorCalculationId}`}
          icon={<IconPrint />}
          tag="link"
          target="_blank"
        />
      )}
      {!isExternal && (
        <IconButton
          ariaLabel="Open contractor's calculation in new tab"
          href={`${AppRoute.CONTRACTOR_CALCULATION}/${contractorCalculationId}`}
          icon={<IconNewWindow />}
          tag="link"
          target="_blank"
        />
      )}
    </div>
  </div>
);

export default SidebarHeader;
