import { doGet, doPatch } from './axios-config';

import { ApiRoute } from 'src/constants';
import type { IBaseRate, TApiResponse } from 'src/interfaces';

export async function fetchBaseRate() {
  try {
    const result = await doGet<IBaseRate>(ApiRoute.BASE_RATE);

    return result.data;
  } catch (err) {
    console.log(err);
  }
}

export async function fetchBaseRateChange(value: IBaseRate): Promise<TApiResponse<IBaseRate>> {
  try {
    const result = await doPatch<IBaseRate>(ApiRoute.BASE_RATE, value);

    return {
      isSuccess: true,
      data: result.data,
    };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
