import { CalculationStatus, CalculationStatusShown } from './calculation-status';
import { ContractorStatus, ContractorStatusShown } from './contractor-status';

export const TableButtonStatusLabel = {
  ...ContractorStatusShown,
  ...CalculationStatusShown,
};

export const TableButtonActionLabel = {
  [ContractorStatus.ACTIVE]: 'Activate',
  [ContractorStatus.BLOCKED]: 'Block',
  [CalculationStatus.APPROVED]: 'Approve',
  [CalculationStatus.DECLINED]: 'Decline',
};
