import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Sidebar from 'src/components/common/sidebar';

import calculationDetailsStore from 'src/stores/calculation-details-store';
import ratesStore from 'src/stores/rates-store';

import './external-sidebar.scss';

const ExternalSidebar = observer(() => {
  const { id } = useParams();
  const { clearStore, loadCalculation } = calculationDetailsStore;

  useEffect(() => {
    if (id) {
      ratesStore.loadCatalog();
      void loadCalculation(id);
    }
    return clearStore;
  }, [clearStore, id, loadCalculation]);

  return <div className="external-sidebar">{id && <Sidebar id={id} isExternal />}</div>;
});

export default ExternalSidebar;
