import { observer } from 'mobx-react-lite';

import NavbarItem from './navbar-item';
import { ReactComponent as AccountingIcon } from 'src/assets/icons/accounting.svg';
import { ReactComponent as ExitIcon } from 'src/assets/icons/exit.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings.svg';

import globalAppStore from 'src/stores/global-app-store';

import { AppRoute, LIST_ADDITIONAL_ACTIVE_SETTINGS_ROUTES, PUBLIC_URL } from 'src/constants';

import './navbar.scss';

const Navbar = observer(() => (
  <nav className="navbar">
    <img
      className="navbar__logo"
      alt="Bezikaron logo"
      height={36}
      src={`${PUBLIC_URL}/logos/logo-small-white.svg`}
      width={36}
    />

    <ul className="navbar__menu">
      <NavbarItem
        linkProps={{ url: AppRoute.CONTRACTORS_ACCOUNTING }}
        tooltipText="Contractors Accounting"
      >
        <AccountingIcon />
      </NavbarItem>
    </ul>

    <ul className="navbar__menu  navbar__menu_bottom">
      <NavbarItem
        linkProps={{ url: AppRoute.SETTINGS_CONTRACTORS }}
        listAdditionalActiveRoutes={LIST_ADDITIONAL_ACTIVE_SETTINGS_ROUTES}
        tooltipText="Settings"
      >
        <SettingsIcon />
      </NavbarItem>

      <NavbarItem buttonProps={{ onClick: globalAppStore.logout }} tooltipText="Logout">
        <ExitIcon />
      </NavbarItem>
    </ul>
  </nav>
));

export default Navbar;
