import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Input } from '@devopsafs/react-ui-components';

import ActionButton from 'src/components/common/action-button';
import { ReactComponent as SaveIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as CancelIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';

import { CURRENCY_CENTS_COUNT, MAX_BONUS_VALUE } from 'src/constants';
import { formatMoney } from 'src/utils';
import type { BonusLineProps } from './bonus-line.props';

import './bonus-line.scss';

const BonusLine = ({
  isEditable,
  isFine,
  isLoading,
  isPrintable,
  label,
  value,
  onChange,
}: BonusLineProps) => {
  const [isAdding, setIsAdding] = useState(false);
  const [newValue, setNewValue] = useState(0);
  const isNegativeValue = isFine && value > 0;

  const handleAddClick = useCallback(() => {
    setNewValue(0);
    setIsAdding(true);
  }, []);

  const handleRemoveClick = useCallback(() => onChange(0), [onChange]);

  const handleSaveClick = useCallback(() => {
    const updatedValue = newValue * CURRENCY_CENTS_COUNT;
    if (updatedValue !== value) {
      onChange(updatedValue);
    }
    setIsAdding(false);
  }, [newValue, onChange, value]);

  const handleCancelClick = useCallback(() => {
    setNewValue(0);
    setIsAdding(false);
  }, []);

  const handleValueChange = useCallback((value: string) => {
    const newValue = parseInt(value, 10) || 0;
    setNewValue(Math.min(newValue, MAX_BONUS_VALUE));
  }, []);

  return (
    <div className={classNames('bonus-line', isEditable && 'bonus-line_editable')}>
      <span className="bonus-line__label">{label}:</span>
      <span
        className={classNames(
          'bonus-line__value',
          isNegativeValue && !isPrintable && 'bonus-line__value_fine'
        )}
      >
        {isNegativeValue && '-'}
        {(!isEditable || !!value) && formatMoney(value, true)}
      </span>
      {isEditable && (
        <span className="bonus-line__actions">
          {value ? (
            <ActionButton
              ariaLabel="Delete"
              color="red"
              icon={<TrashIcon />}
              isLoading={isLoading}
              onClick={handleRemoveClick}
            />
          ) : isAdding ? (
            <>
              <Input
                className="bonus-line__input"
                isDisabled={isLoading}
                size="mini"
                value={newValue.toString()}
                onChange={handleValueChange}
              />
              <ActionButton
                ariaLabel="Save"
                color="green"
                icon={<SaveIcon />}
                isLoading={isLoading}
                onClick={handleSaveClick}
              />
              <ActionButton
                ariaLabel="Cancel"
                color="red"
                icon={<CancelIcon />}
                isLoading={isLoading}
                onClick={handleCancelClick}
              />
            </>
          ) : (
            <ActionButton
              ariaLabel="Add"
              color="green"
              icon={<PlusIcon />}
              isLoading={isLoading}
              onClick={handleAddClick}
            />
          )}
        </span>
      )}
    </div>
  );
};

export default memo(BonusLine);
