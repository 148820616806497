import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { DateFormat, DatePeriod, LocaleFormat } from 'src/constants';
import type { IDateRange } from 'src/interfaces';

dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(utc);

const periodHandlers = {
  [DatePeriod.WEEK]: getWeekValues,
  [DatePeriod.MONTH]: getMonthValues,
  [DatePeriod.DAY]: getDayValues,
};

export function getCurrentDate() {
  return dayjs().format(LocaleFormat.he);
}

export function getWeekValues() {
  return {
    from: dayjs().locale('he').startOf('w').format(LocaleFormat.he),
    to: dayjs().locale('he').endOf('w').format(LocaleFormat.he),
  };
}

export function getMonthValues() {
  return {
    from: dayjs().startOf('M').format(LocaleFormat.he),
    to: dayjs().endOf('M').format(LocaleFormat.he),
  };
}

export function getDayValues() {
  const today = getCurrentDate();

  return {
    from: today,
    to: today,
  };
}

export function formatDate(date: string, format = LocaleFormat.he) {
  return dayjs.utc(date).format(format);
}

export const formatDateFromSlashToDot = (str: string) => str.replace(/\//g, '.');

export const checkDateValid = (date: string, format = LocaleFormat.he) =>
  dayjs(date, format, true).isValid();

export function convertDate(date: string, sourceFormat: string, destinationFormat: string) {
  return dayjs(date, sourceFormat).format(destinationFormat);
}

export function getDatePeriodValues(period: DatePeriod) {
  return periodHandlers[period]?.() || periodHandlers[DatePeriod.WEEK]();
}

export function getPeriodByDatesRange({ dateFrom, dateTo }: IDateRange) {
  const currentPeriod = Object.entries(periodHandlers).find(([, fn]) => {
    const { from, to } = fn();
    return dateFrom === from && dateTo === to;
  })?.[0] as DatePeriod;

  return currentPeriod || null;
}

export function getApiFormatDateRange({ dateFrom, dateTo }: IDateRange) {
  const getDay = (date: string) => date.substring(0, 2);
  const getMonth = (date: string) => date.substring(3, 5);
  const getYear = (date: string) => date.substring(6, 10);

  return {
    from: `${getYear(dateFrom)}/${getMonth(dateFrom)}/${getDay(dateFrom)}`,
    to: `${getYear(dateTo)}/${getMonth(dateTo)}/${getDay(dateTo)}`,
  };
}

export const checkIsDateBefore = (
  startDate: string,
  endDate: string,
  dateFormat = LocaleFormat.he
) => dayjs(startDate, dateFormat).isBefore(dayjs(endDate, dateFormat));

export const getYearWeek = (date: string) => `${dayjs(date).week()}-${dayjs(date).year()}`;

export const getWorkWeekLabel = (date: string) =>
  `${dayjs(date).day(0).format(DateFormat.MONTH_DAY)} - ${dayjs(date)
    .day(5)
    .format(DateFormat.MONTH_DAY)}`;

export const isSaturday = (date: Date) => {
  return dayjs(date).weekday() === 6;
};
