import { makeAutoObservable } from 'mobx';

import DateRangeStore from 'src/stores/common/date-range-store';

import { CalculationStatus, DEFAULT_PERIOD } from 'src/constants';

const INITIAL_PERIOD = DEFAULT_PERIOD;
const INITIAL_CONTRACTOR = '';
const INITIAL_STATUS = '';

class FiltersStore {
  dateRangeStore = new DateRangeStore(INITIAL_PERIOD);
  selectedContractor: string = INITIAL_CONTRACTOR; // contractorId
  selectedCalculationStatus: CalculationStatus | '' = INITIAL_STATUS;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setSelectedContractor(id: string) {
    this.selectedContractor = id;
  }

  setSelectedCalculationStatus(status: CalculationStatus | '') {
    this.selectedCalculationStatus = status;
  }

  resetStore() {
    this.dateRangeStore.setActivePeriod(INITIAL_PERIOD);
    this.setSelectedContractor(INITIAL_CONTRACTOR);
    this.setSelectedCalculationStatus(INITIAL_STATUS);
  }
}

export default new FiltersStore();
