export const trimSpaces = <T>(value: T): T => {
  if (typeof value === 'string') {
    return value.replace(/\s\s+/g, ' ').trim() as T;
  } else if (Array.isArray(value)) {
    return value.map(trimSpaces) as T;
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((acc: T, key: keyof T) => {
      acc[key] = trimSpaces(value[key]);
      return acc;
    }, {} as T);
  } else {
    return value;
  }
};
