import { mapApiToOrder } from './map-api-to-order';
import { mapApiToPerson } from './map-api-to-person';

import type {
  IApiCalculation,
  IApiDetailedCalculation,
  ICalculation,
  IDetailedCalculation,
} from 'src/interfaces';

export const mapApiToCalculation = ({
  comments,
  contractor,
  ...rest
}: IApiCalculation): ICalculation => {
  return {
    ...rest,
    comments: comments ?? [],
    contractor: mapApiToPerson(contractor),
  };
};

export const mapApiToDetailedCalculation = ({
  comments,
  contractor,
  workorders,
  ...rest
}: IApiDetailedCalculation): IDetailedCalculation => {
  return {
    ...rest,
    comments: comments ?? [],
    contractor: mapApiToPerson(contractor),
    workorders: workorders.map(mapApiToOrder),
  };
};
