import React from 'react';
import ReactDOM from 'react-dom/client';
import { setInputCustomValuesByType, UIKitThemeProvider } from '@devopsafs/react-ui-components';

import App from './app';

const DESIGN_TOKEN_TYPE = 'bezikaron-projects';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

setInputCustomValuesByType(DESIGN_TOKEN_TYPE);

root.render(
  <UIKitThemeProvider designTokenType={DESIGN_TOKEN_TYPE}>
    <App />
  </UIKitThemeProvider>
);
