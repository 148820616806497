export const addSearchParam = (key: string, value: string) => {
  const { pathname, search } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set(key, value);
  window.history.replaceState(null, '', `${pathname}?${searchParams.toString()}`);
};

export const removeSearchParam = (key: string) => {
  const { pathname, search } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.delete(key);
  const searchString = searchParams.toString();
  window.history.replaceState(null, '', `${pathname}${searchString ? `?${searchString}` : ''}`);
};

export const getSearchParam = (key: string) => {
  return new URLSearchParams(window.location.search).get(key);
};
