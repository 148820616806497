export const InputMaxLength = {
  COMMENT: 300,
  EMAIL: 254,
  TEXT: 512,
};

export const InputMinLength = {
  NAME: 2,
  RATE_NAME: 1,
  TEXT: 3,
};

export enum InputType {
  EMAIL = 'email',
  NAME = 'name',
  PHONE = 'phone',
  RATE = 'rate',
  TEXT = 'text',
}

export const MAX_BONUS_VALUE = 9999;
