import React from 'react';
import { observer } from 'mobx-react-lite';

import Financials from 'src/components/common/financials';
import TopPanel from 'src/components/common/top-panel';

import globalAppStore from 'src/stores/global-app-store';

import type { HeaderProps } from './header.props';

const Header = observer(({ isSettingsHeader }: HeaderProps) => {
  const { user } = globalAppStore;
  const { fullName = '' } = user || {};

  return (
    <>
      {isSettingsHeader ? (
        <TopPanel title="Accounting Settings" />
      ) : (
        <TopPanel
          subtitle={`Welcome${fullName ? `, ${fullName}` : ''}!`}
          title="Contractor Accounting"
        >
          <Financials />
        </TopPanel>
      )}
    </>
  );
});

export default Header;
