import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import dayjs from 'dayjs';
import { Input } from '@devopsafs/react-ui-components';

import DatePickerIcon from './components/date-picker-icon';

import useKeyUpGlobal from 'src/hooks/use-key-up-global';
import {
  flatpickrLocaleFormat,
  Key,
  KeyUpGlobalConstants,
  LocaleFormat,
  Mask,
  MaskPlaceholder,
} from 'src/constants';
import type { InputDatePickerProps } from './input-date-picker.props';

import 'flatpickr/dist/flatpickr.css';

const InputDatePicker = ({
  className,
  disableNextDates,
  isChecked,
  isDisabled,
  isStatic = false,
  position = 'auto',
  value,
  wrapperClassname,
  checkDisabledDay,
  onCalendarVisibilityChange,
  onChange,
  ...props
}: InputDatePickerProps) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDatePick = useCallback(
    (value: Date[]) => {
      const formattedValue =
        value[0] && dayjs(value[0]).isValid() ? dayjs(value[0]).format(LocaleFormat.he) : '';

      onChange(formattedValue);
    },
    [onChange]
  );

  const keyDownListener = useCallback((event: KeyboardEvent) => {
    if (event.key === Key.ESCAPE) {
      event.stopPropagation();
    }
  }, []);

  const fp = useRef<Flatpickr>(null);

  const toggleCalendar = useCallback(() => {
    if (!fp?.current?.flatpickr) {
      return;
    }

    fp.current.flatpickr.toggle();
  }, []);

  useEffect(() => {
    if (isCalendarOpen) {
      window.addEventListener('keydown', keyDownListener, true);
    } else {
      window.removeEventListener('keydown', keyDownListener, true);
    }

    return () => {
      window.removeEventListener('keydown', keyDownListener, true);
    };
  }, [isCalendarOpen, keyDownListener]);

  useKeyUpGlobal(
    Key.ESCAPE,
    toggleCalendar,
    KeyUpGlobalConstants.CALENDAR_ORDER_IN_KEY_QUEUE,
    !isCalendarOpen
  );

  return (
    <Flatpickr
      className={wrapperClassname}
      options={{
        allowInput: true,
        allowInvalidPreload: true,
        altInputClass: 'hide',
        clickOpens: false,
        dateFormat: flatpickrLocaleFormat.he,
        ...(checkDisabledDay && { disable: [checkDisabledDay] }),
        disableMobile: true,
        ...(disableNextDates && { maxDate: 'today' }),
        locale: {
          weekdays: {
            shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            longhand: [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ],
          },
        },
        monthSelectorType: 'static',
        nextArrow: `<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.3 6a.9.9 0 0 1-.264.636l-4.8 4.8a.9.9 0 1 1-1.272-1.272L5.127 6 .964 1.836A.9.9 0 1 1 2.236.564l4.8 4.8A.9.9 0 0 1 7.3 6z" fill="#444444"/></svg>`,
        prevArrow: `<svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M.6 6a1 1 0 0 1 .293-.707l4.8-4.8a1 1 0 1 1 1.414 1.414L3.014 6l4.093 4.093a1 1 0 0 1-1.414 1.414l-4.8-4.8A1 1 0 0 1 .6 6z" fill="#444444"/></svg>`,
        position,
        static: isStatic,
        wrap: true,
      }}
      ref={fp}
      value={value}
      onChange={handleDatePick}
      onClose={(_selectedDates, _dateStr, instance) => {
        setIsCalendarOpen(false);
        onCalendarVisibilityChange?.(false);
        if (!isStatic) {
          instance.calendarContainer.classList.remove('flatpickr-calendar_minified');
        }
      }}
      onOpen={(_selectedDates, _dateStr, instance) => {
        setIsCalendarOpen(true);
        onCalendarVisibilityChange?.(true);
        if (!isStatic) {
          setTimeout(() => {
            toggleMinifiedCalendarContainer(instance.calendarContainer);
            instance.calendarContainer.focus();
          }, 0);
        } else {
          setTimeout(() => instance.calendarContainer.focus(), 0);
        }
      }}
      onDayCreate={(_dObj, _dStr, _fp, dayElem) => {
        dayElem.setAttribute('tabindex', '0');
      }}
      onReady={(_selectedDates, _dateStr, instance) => {
        const prevMonth = instance.prevMonthNav;
        const nextMonth = instance.nextMonthNav;
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === Key.ENTER) {
            (event as any).currentTarget.click();
          }
        };

        [prevMonth, nextMonth].forEach((month) => {
          month.setAttribute('tabindex', '0');
          month.addEventListener('keydown', handleKeyDown);
        });

        instance.calendarContainer.setAttribute('tabindex', '0');
      }}
    >
      <Input
        className={className}
        icon={
          <DatePickerIcon
            isChecked={isChecked}
            isDisabled={isDisabled}
            toggleCalendar={toggleCalendar}
          />
        }
        isDisabled={isDisabled}
        data-input
        mask={Mask.DATE}
        placeholder={MaskPlaceholder.DATE}
        value={value}
        onChange={onChange}
        {...props}
      />
    </Flatpickr>
  );
};

const toggleMinifiedCalendarContainer = (calendarContainer: HTMLDivElement) => {
  const isElementVerticallyInViewport = (element: HTMLElement) => {
    const { top, bottom } = window.getComputedStyle(element);
    return parseInt(top, 10) >= 0 && parseInt(bottom, 10) >= 0;
  };

  calendarContainer.classList.toggle(
    'flatpickr-calendar_minified',
    !isElementVerticallyInViewport(calendarContainer)
  );
};

export default memo(InputDatePicker);
