import { doDelete, doGet, doPut } from './axios-config';

import { mapApiToDetailedCalculation } from 'src/adapters';
import { ApiRoute, CalculationStatus } from 'src/constants';
import { downloadFile } from 'src/utils';
import type {
  IApiDetailedCalculation,
  ICalculationsFilter,
  IDetailedCalculation,
  TApiResponse,
} from 'src/interfaces';

interface IExportCalculationsParams {
  filter: ICalculationsFilter;
}

function getCalculationsFileName(params: IExportCalculationsParams) {
  return `calculations_${Object.entries(params.filter)
    .filter(([, value]) => !!value)
    .flat()
    .map((value) =>
      typeof value === 'object' && !Array.isArray(value)
        ? Object.entries(value)
            .map(([key, property]) => `${key}-${property}`)
            .join(' ')
        : value
    )
    .join('-')
    .replace(/[_ ]/g, '-')}.xlsx`;
}

export async function fetchCalculation(id: string) {
  try {
    const result = await doGet<IApiDetailedCalculation>(ApiRoute.CALCULATION(id));

    return mapApiToDetailedCalculation(result.data);
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function fetchCalculationsExport(
  params: IExportCalculationsParams
): Promise<TApiResponse> {
  try {
    const response = await doGet(ApiRoute.CALCULATIONS_EXPORT, {
      params: { filter: JSON.stringify(params.filter) },
      responseType: 'blob',
    });
    downloadFile(response, getCalculationsFileName(params));
    return { isSuccess: true };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchCalculationChangeBonus(
  id: string,
  value: number
): Promise<TApiResponse<IDetailedCalculation>> {
  try {
    const { data } = await doPut<IApiDetailedCalculation>(ApiRoute.CALCULATION_BONUS(id), {
      value,
    });

    return { isSuccess: true, data: mapApiToDetailedCalculation(data) };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchCalculationChangeFine(
  id: string,
  value: number
): Promise<TApiResponse<IDetailedCalculation>> {
  try {
    const { data } = await doPut<IApiDetailedCalculation>(ApiRoute.CALCULATION_FINE(id), { value });

    return { isSuccess: true, data: mapApiToDetailedCalculation(data) };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchCalculationChangeStatus(
  id: string,
  status: CalculationStatus,
  { prevComment, newComment }: { prevComment: string; newComment: string }
): Promise<TApiResponse<IDetailedCalculation>> {
  const shouldEditComment = status !== CalculationStatus.WAITING && prevComment !== newComment;
  try {
    if (shouldEditComment && prevComment) {
      await doDelete<never>(ApiRoute.CALCULATION_COMMENTS(id));
    }

    const { data } = await doPut<IApiDetailedCalculation>(ApiRoute.CALCULATION_STATUS(id), {
      value: status,
      ...(shouldEditComment && newComment && { comment: newComment }),
    });

    return { isSuccess: true, data: mapApiToDetailedCalculation(data) };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
