import { useEffect, useRef } from 'react';

import { useGoogleOAuth } from 'src/contexts/google-oauth-provider';
import type { GoogleLoginProps } from './google-login.props';

export default function GoogleLogin({
  size = 'large',
  width,
  onError,
  onSuccess,
}: GoogleLoginProps) {
  const btnContainerRef = useRef<HTMLDivElement>(null);
  const { clientId, scriptLoadedSuccessfully } = useGoogleOAuth();
  const onSuccessRef = useRef(onSuccess);
  const onErrorRef = useRef(onError);

  onSuccessRef.current = onSuccess;
  onErrorRef.current = onError;

  useEffect(() => {
    if (!scriptLoadedSuccessfully) {
      return;
    }

    window.google?.accounts?.id?.initialize({
      client_id: clientId,
      ux_mode: 'popup',
      callback: (response: google.accounts.id.CredentialResponse) => {
        if (!response?.credential) {
          return onErrorRef.current?.();
        }

        const { credential } = response;
        onSuccessRef.current(credential);
      },
    });

    window.google?.accounts?.id?.renderButton(btnContainerRef.current!, {
      locale: 'en_US',
      logo_alignment: 'left',
      text: 'signin_with',
      theme: 'outline',
      type: 'standard',
      shape: 'rectangular',
      size,
      width,
    });
  }, [clientId, scriptLoadedSuccessfully, size, width]);

  return <div className="google-login" ref={btnContainerRef} />;
}
