export const ApiRoute = {
  BASE_RATE: 'base-rate',
  BRIGADE: (brigadeId: string) => `brigades/${brigadeId}`,
  BRIGADES: 'brigades',
  CALCULATION: (calculationId: string) => `calculations/${calculationId}`,
  CALCULATION_BONUS: (calculationId: string) => `calculations/${calculationId}/bonus`,
  CALCULATION_COMMENTS: (calculationId: string) => `calculations/${calculationId}/comments`,
  CALCULATION_FINE: (calculationId: string) => `calculations/${calculationId}/fine`,
  CALCULATION_STATUS: (calculationId: string) => `calculations/${calculationId}/status`,
  CALCULATIONS: 'calculations',
  CALCULATIONS_EXPORT: 'calculations/xlsx',
  CATALOG: 'catalog',
  CATALOG_ITEM: (catalogItemId: string) => `catalog/${catalogItemId}`,
  CONTRACTOR: (contractorId: string) => `contractors/${contractorId}`,
  CONTRACTOR_RATE: (brigadeId: string, contractorId: string) =>
    `brigades/${brigadeId}/contractor/${contractorId}/rates`,
  CONTRACTOR_STATUS: (contractorId: string) => `contractors/${contractorId}/status`,
  CONTRACTORS: 'contractors',
  ORDER_ITEM_STATUS: (calculationId: string, itemId: string) =>
    `calculations/${calculationId}/items/${itemId}/status`,
  ORDER_ITEMS: (calculationId: string, orderId: string) =>
    `calculations/${calculationId}/workorders/${orderId}/items`,
  ORDER_STATUS: (calculationId: string, orderId: string) =>
    `calculations/${calculationId}/workorders/${orderId}/status`,
  SIGN_IN: 'users/sign-in/google',
  SYNC: 'sync',
  WS_CALCULATIONS_STAT: 'ws/calculations-stat',
};
