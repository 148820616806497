import { makeAutoObservable } from 'mobx';

import contractorsStore from 'src/stores/contractors-store';
import popupStore from 'src/stores/popup-store';

import {
  fetchBrigadeCreate,
  fetchBrigadeDelete,
  fetchBrigadeRatesChange,
  fetchBrigades,
} from 'src/api';
import { ErrorText, InputType, LIST_FIELD_RATES, PopupName, Regex } from 'src/constants';
import { getDeepObjectDiff } from 'src/utils';
import type { IBrigade, IContractor, IForm, TRates } from 'src/interfaces';

type TBrigadeRatesField = keyof TRates;
type TBrigadeRatesForm = IForm<TBrigadeRatesField>;

class PartnershipStore {
  async init() {
    this.clearStore();
    await this.loadBrigades();
  }

  brigadesList: IBrigade[] = [];
  forms: Record<string, Record<string, TBrigadeRatesForm>> = {};
  isLoading: boolean = false;
  processingBrigadeId: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setBrigadesList(brigadesList: IBrigade[]) {
    this.brigadesList = brigadesList;
  }

  setForm(brigadeId: string, contractorId: string, formData: TBrigadeRatesForm) {
    this.forms[brigadeId] = { [contractorId]: formData };
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setProcessingBrigadeId(processingBrigadeId: string) {
    this.processingBrigadeId = processingBrigadeId;
  }

  get brigadeListSorted() {
    return this.brigadesList
      .map((brigade) => {
        const contractors = brigade.contractors
          .slice()
          .sort((contractor) => (contractor.brigadier ? -1 : 1));
        const brigadeName = contractors
          .map((contractor) => contractor.lastName || contractor.firstName)
          .filter(Boolean)
          .join(', ');

        return { ...brigade, brigadeName, contractors };
      })
      .sort((a, b) => (a.brigadeName < b.brigadeName ? -1 : 1));
  }

  addTeam(contractorsList: IContractor[]) {
    popupStore.showPopup(PopupName.CREATE_TEAM, {
      contractorsList,
      onCreateTeam: async (onCloseButtonClick, firstContractorId, secondContractorId) => {
        this.setLoading(true);
        const contractors = [firstContractorId, secondContractorId]
          .filter((id): id is string => !!id)
          .map((id) => ({ id }));
        const { data, isSuccess, error } = await fetchBrigadeCreate({
          contractors,
        });
        if (isSuccess) {
          this.setBrigadesList([...this.brigadesList, data]);
          await contractorsStore.loadContractors(true);
          onCloseButtonClick();
        } else {
          popupStore.showPopup(PopupName.WARN, {
            title: ErrorText.REQUEST_FAILURE,
            text: error || ErrorText.DEFAULT,
          });
        }

        this.setLoading(false);
      },
    });
  }

  removeTeam(brigadeId: string) {
    popupStore.showPopup(PopupName.CONFIRM, {
      mainActionLabel: 'Delete',
      secondaryActionLabel: 'Cancel',
      text: 'Are you sure you want to delete this Team?',
      title: 'Delete Team?',
      mainActionHandler: async (onCloseButtonClick?: () => void) => {
        this.setProcessingBrigadeId(brigadeId);
        const { error, isSuccess } = await fetchBrigadeDelete(brigadeId);
        if (isSuccess) {
          this.removeForm(brigadeId);
          this.setBrigadesList(this.brigadesList.filter(({ id }) => id !== brigadeId));
          await contractorsStore.loadContractors(true);
          onCloseButtonClick?.();
        } else {
          popupStore.showPopup(PopupName.WARN, {
            title: ErrorText.REQUEST_FAILURE,
            text: error || ErrorText.DEFAULT,
          });
        }
        this.setProcessingBrigadeId('');
      },
    });
  }

  getForm(brigadeId: string, contractorId: string) {
    return this.forms[brigadeId]?.[contractorId];
  }

  checkIsEditingForm(brigadeId: string, contractorId: string) {
    return brigadeId in this.forms && contractorId in this.forms[brigadeId];
  }

  checkIsEditingDisable(brigadeId: string, contractorId: string) {
    return brigadeId in this.forms && !(contractorId in this.forms[brigadeId]);
  }

  setFieldValid(
    brigadeId: string,
    contractorId: string,
    fieldName: TBrigadeRatesField,
    isValid: boolean
  ) {
    const form = this.getForm(brigadeId, contractorId);

    this.setForm(brigadeId, contractorId, {
      ...form,
      [fieldName]: { ...form[fieldName], isValid },
    });
  }

  setFieldValue(
    brigadeId: string,
    contractorId: string,
    fieldName: TBrigadeRatesField,
    value: string
  ) {
    if (!value || Regex.RATE_INPUT.test(value)) {
      const form = this.getForm(brigadeId, contractorId);

      const formattedValue = value.replace(',', '.');

      this.setForm(brigadeId, contractorId, {
        ...form,
        [fieldName]: { ...form[fieldName], isValid: true, value: formattedValue },
      });
    }
  }

  checkIsInputValid(brigadeId: string, contractorId: string, fieldName: TBrigadeRatesField) {
    const { value } = this.getForm(brigadeId, contractorId)[fieldName];

    return !!value && Regex.RATE.test(value);
  }

  checkIsFormValid(brigadeId: string, contractorId: string) {
    return Object.keys(this.getForm(brigadeId, contractorId)).reduce((isFormValid, field) => {
      const isValid = this.checkIsInputValid(brigadeId, contractorId, field);

      if (!isValid) {
        isFormValid = false;
        this.setFieldValid(brigadeId, contractorId, field, false);
      }

      return isFormValid;
    }, true);
  }

  clearStore() {
    Object.keys(this.forms).forEach((id) => {
      this.removeForm(id);
    });
  }

  getInputProps(brigadeId: string, contractorId: string, fieldName: TBrigadeRatesField) {
    const { value, isValid } = this.getForm(brigadeId, contractorId)[fieldName];

    return {
      value,
      isValid,
      size: 'mini' as const,
      onChange: (value: string) => this.setFieldValue(brigadeId, contractorId, fieldName, value),
    };
  }

  editForm(brigadeId: string, contractorId: string) {
    const rates = this.brigadesList
      .find((brigade) => brigade.id === brigadeId)
      ?.contractors.find((contractor) => contractor.id === contractorId)!.rates!;

    this.setForm(
      brigadeId,
      contractorId,
      LIST_FIELD_RATES.reduce(
        (form, field) => ({
          ...form,
          [field]: {
            isValid: true,
            value: rates[field].toString(),
            type: InputType.TEXT,
          },
        }),
        {} as TBrigadeRatesForm
      )
    );
  }

  async loadBrigades() {
    this.setLoading(true);

    const brigadesList = await fetchBrigades();
    this.setBrigadesList(brigadesList);

    this.setLoading(false);
  }

  removeForm(brigadeId: string) {
    delete this.forms[brigadeId];
  }

  async changeTeamRates(brigadeId: string, contractorId: string) {
    if (this.checkIsFormValid(brigadeId, contractorId)) {
      const { bundle, product, service } = this.getForm(brigadeId, contractorId);

      const updatedBrigadeIndex = this.brigadesList.findIndex(({ id }) => id === brigadeId);
      const prevContractorRates =
        this.brigadesList[updatedBrigadeIndex].contractors.find(({ id }) => id === contractorId)
          ?.rates ?? {};

      const newRates = {
        bundle: Number(bundle.value),
        product: Number(product.value),
        service: Number(service.value),
      };

      const diff = getDeepObjectDiff(prevContractorRates, newRates);

      if (!Object.keys(diff).length) {
        return this.removeForm(brigadeId);
      }

      this.setProcessingBrigadeId(brigadeId);

      const { data, error, isSuccess } = await fetchBrigadeRatesChange(
        brigadeId,
        contractorId,
        newRates
      );

      if (isSuccess) {
        this.setBrigadesList([
          ...this.brigadesList.slice(0, updatedBrigadeIndex),
          data,
          ...this.brigadesList.slice(updatedBrigadeIndex + 1),
        ]);

        this.removeForm(brigadeId);
      } else {
        popupStore.showPopup(PopupName.WARN, {
          title: ErrorText.REQUEST_FAILURE,
          text: error || ErrorText.DEFAULT,
        });
      }

      this.setProcessingBrigadeId('');
    }
  }
}

export default new PartnershipStore();
