import { doGet, doPatch, doPost, doPut } from './axios-config';

import { mapApiToContractor } from 'src/adapters';
import { ApiRoute, ContractorStatus } from 'src/constants';
import type {
  IApiContractor,
  IContractor,
  TApiResponse,
  TChangeApiContractor,
  TCreateApiContractor,
} from 'src/interfaces';

export async function fetchContractors(withFilter = false) {
  const params = {
    filter: JSON.stringify({
      status: 'active',
      in_brigade: false,
    }),
  };

  try {
    const result = await doGet<IApiContractor[]>(
      ApiRoute.CONTRACTORS,
      withFilter ? { params } : undefined
    );

    return result.data.map(mapApiToContractor);
  } catch (err: any) {
    console.log(err);
    return [];
  }
}

export async function fetchContractorCreate(
  data: TCreateApiContractor
): Promise<TApiResponse<IContractor>> {
  try {
    const result = await doPost<IApiContractor>(ApiRoute.CONTRACTORS, data);

    return {
      isSuccess: true,
      data: mapApiToContractor(result.data),
    };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchContractorChange(
  id: string,
  data: TChangeApiContractor
): Promise<TApiResponse<IContractor>> {
  try {
    const result = await doPatch<IApiContractor>(ApiRoute.CONTRACTOR(id), data);

    return {
      isSuccess: true,
      data: mapApiToContractor(result.data),
    };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchContractorChangeStatus(
  id: string,
  newStatus: ContractorStatus
): Promise<TApiResponse> {
  try {
    await doPut<never>(ApiRoute.CONTRACTOR_STATUS(id), {
      value: newStatus,
    });

    return { isSuccess: true };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
