import { makeAutoObservable } from 'mobx';

import { DateError, DatePeriod } from 'src/constants';
import {
  checkDateValid,
  checkIsDateBefore,
  formatDateFromSlashToDot,
  getDatePeriodValues,
  getPeriodByDatesRange,
} from 'src/utils';
import type { IDateRange } from 'src/interfaces';

type DateRangeProperty = keyof IDateRange;

class DateRangeStore {
  init(initialPeriod: DatePeriod) {
    this.setActivePeriod(initialPeriod);
  }

  dateRange: IDateRange = {
    dateFrom: '',
    dateTo: '',
  };

  constructor(initialPeriod?: DatePeriod) {
    makeAutoObservable(this, {}, { autoBind: true });

    if (initialPeriod) {
      this.init(initialPeriod);
    }
  }

  getValidationProps(dateUnit: DateRangeProperty) {
    const date = formatDateFromSlashToDot(this.dateRange[dateUnit]);
    const isValid = checkDateValid(date) || date === '';
    const textError = isValid ? '' : DateError.DEFAULT;

    return { isValid, textError };
  }

  setDate(dateFrom: string, dateTo: string) {
    this.dateRange = { dateFrom, dateTo };
  }

  setDateUnit(dateUnit: DateRangeProperty, value: string) {
    if (value !== this.dateRange[dateUnit]) {
      this.dateRange = { ...this.dateRange, [dateUnit]: value };
    }
  }

  setActivePeriod(period: DatePeriod) {
    const { from, to } = getDatePeriodValues(period);
    this.setDate(from, to);
  }

  getDateUnitValue(dateUnit: DateRangeProperty) {
    return this.dateRange[dateUnit];
  }

  handleChangeDateFrom(value: string) {
    this.setDateUnit('dateFrom', value);
  }

  handleChangeDateTo(value: string) {
    this.setDateUnit('dateTo', value);
  }

  getInputProps(dateUnit: DateRangeProperty) {
    return {
      value: this.getDateUnitValue(dateUnit) ?? '',
    };
  }

  clearDate() {
    this.dateRange = {
      dateFrom: '',
      dateTo: '',
    };
  }

  get activePeriod() {
    return getPeriodByDatesRange(this.dateRange);
  }

  get isDateValid() {
    let isValid = checkDateValid(this.dateRange.dateFrom) && checkDateValid(this.dateRange.dateTo);
    let textError = '';

    if (isValid && checkIsDateBefore(this.dateRange.dateTo, this.dateRange.dateFrom)) {
      isValid = false;
      textError = DateError.START_DATE_IS_GREATER_THAN_END_DATE;
    }

    return { isValid, textError };
  }

  get isEmptyDate() {
    const { dateFrom, dateTo } = this.dateRange;

    return !dateFrom && !dateTo;
  }
}

export default DateRangeStore;
