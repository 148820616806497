import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import ActionButton from 'src/components/common/action-button';
import ExternalLink from 'src/components/common/external-link';
import DealSelect from '../deal-select';
import ReworkButton from '../rework-button';
import { ReactComponent as SaveIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as CancelIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { ReactComponent as RotateIcon } from 'src/assets/icons/rotate.svg';
import { ReactComponent as ShareIcon } from 'src/assets/icons/share.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';

import calculationDetailsStore from 'src/stores/calculation-details-store';
import ratesStore from 'src/stores/rates-store';

import { CatalogItemLabel } from 'src/constants';
import { formatMoney } from 'src/utils';
import type { SidebarDealProps } from './sidebar-deal.props';

import './sidebar-deal.scss';

const SidebarDeal = observer(
  ({
    isEditable,
    isPrintable,
    order: {
      id: orderId,
      cemetery,
      crmLeadId,
      crmUrl,
      deceased,
      includedInCalculation: dealIncludedInCalculation,
      items,
      total,
      isReworkChild,
      reworkParentCalculations,
    },
  }: SidebarDealProps) => {
    const [isVisibleAddPanel, setVisibleAddPanel] = useState(false);
    const [isTransition, setIsTransition] = useState(false);
    const [newItemId, setNewItemId] = useState('');
    const {
      isProcessing,
      handleAddDealItem,
      handleRemoveDeal,
      handleRemoveDealItem,
      handleRestoreDeal,
      handleRestoreDealItem,
    } = calculationDetailsStore;
    const tooltipText = reworkParentCalculations.length
      ? `Open Parent Calculation${reworkParentCalculations.length > 1 ? 's' : ''}`
      : 'No Parent Calculation';

    const handleHideAddPanel = useCallback(() => {
      setIsTransition(true);
      setVisibleAddPanel(false);
      setNewItemId('');
    }, []);

    const handleShowAddPanel = useCallback(() => {
      setIsTransition(true);
      setVisibleAddPanel(true);
    }, []);

    const handleAddItemClick = useCallback(async () => {
      await handleAddDealItem(orderId, newItemId);
      handleHideAddPanel();
      setNewItemId('');
    }, [orderId, handleAddDealItem, handleHideAddPanel, newItemId]);

    const handleRemoveDealClick = useCallback(() => {
      handleHideAddPanel();
      handleRemoveDeal(orderId);
    }, [handleHideAddPanel, handleRemoveDeal, orderId]);

    return (
      <div className="sidebar-deal">
        <div className="sidebar-deal__header">
          <div
            className={classNames(
              'sidebar-deal__info',
              !dealIncludedInCalculation && 'sidebar-deal__info_excluded'
            )}
          >
            <span
              className={classNames(
                'sidebar-deal__total',
                !dealIncludedInCalculation && 'sidebar-deal__total_excluded'
              )}
            >
              {formatMoney(total, true)}
            </span>
            <span className="sidebar-deal__cemetery">{cemetery}</span>
            <span className="sidebar-deal__deceased">{deceased}</span>
          </div>

          <span className="sidebar-deal__actions">
            {isPrintable ? (
              <span className="sidebar-deal__crm-text">{crmLeadId}</span>
            ) : (
              <ExternalLink className="sidebar-deal__crm-link" href={crmUrl}>
                <ShareIcon className="sidebar-deal__crm-icon" />
                <span className="sidebar-deal__crm-text">{crmLeadId}</span>
              </ExternalLink>
            )}
            {isReworkChild && (
              <ReworkButton
                isPrintable={isPrintable}
                reworkParentCalculations={reworkParentCalculations}
                tooltipText={tooltipText}
              />
            )}
            {!isPrintable && isEditable && (
              <>
                {dealIncludedInCalculation &&
                  (isVisibleAddPanel ? (
                    <ActionButton
                      ariaLabel="Hide panel"
                      color="red"
                      icon={<CancelIcon />}
                      isLoading={isProcessing}
                      onClick={handleHideAddPanel}
                    />
                  ) : (
                    <ActionButton
                      ariaLabel="Add new item"
                      color="green"
                      icon={<PlusIcon />}
                      isLoading={isProcessing}
                      onClick={handleShowAddPanel}
                    />
                  ))}
                {dealIncludedInCalculation ? (
                  <ActionButton
                    ariaLabel="Delete deal"
                    color="red"
                    icon={<TrashIcon />}
                    isLoading={isProcessing}
                    onClick={handleRemoveDealClick}
                  />
                ) : (
                  <ActionButton
                    ariaLabel="Restore deal"
                    color="green"
                    icon={<RotateIcon />}
                    isLoading={isProcessing}
                    onClick={() => handleRestoreDeal(orderId)}
                  />
                )}
              </>
            )}
          </span>
        </div>

        <div
          className={classNames(
            'sidebar-deal__add-panel',
            !isVisibleAddPanel && 'sidebar-deal__add-panel_hidden',
            (!isVisibleAddPanel || isTransition) && 'sidebar-deal__add-panel_overflow'
          )}
          onTransitionEnd={() => setIsTransition(false)}
        >
          <div className="sidebar-deal__add-panel-wrapper">
            <DealSelect
              isDisabled={isProcessing}
              isLoading={ratesStore.isLoading}
              items={ratesStore.ratesList}
              label="Bundle, Product or Service"
              value={newItemId}
              onChange={setNewItemId}
            />
            <ActionButton
              ariaLabel="Add to deal"
              color="green"
              icon={<SaveIcon />}
              isDisabled={!newItemId}
              isLoading={isProcessing}
              onClick={handleAddItemClick}
            />
          </div>
        </div>

        <ul className="sidebar-deal__list">
          {items.map(
            ({
              id,
              cost,
              includedInCalculation,
              isReworked,
              manHours,
              name,
              price,
              rate,
              type,
            }) => (
              <li
                className={classNames(
                  'sidebar-deal__list-item',
                  (!includedInCalculation || !dealIncludedInCalculation || isReworked) &&
                    'sidebar-deal__list-item_excluded'
                )}
                key={id}
              >
                <span className="sidebar-deal__cell">{name}</span>
                <span className="sidebar-deal__cell">{CatalogItemLabel[type]}</span>
                <span className="sidebar-deal__cell sidebar-deal__cell_right">{manHours}</span>
                <span className="sidebar-deal__cell sidebar-deal__cell_right">
                  {formatMoney(price, true)}
                </span>
                <span className="sidebar-deal__cell sidebar-deal__cell_right">{rate}</span>
                <span className="sidebar-deal__cell sidebar-deal__cell_bold sidebar-deal__cell_right">
                  {formatMoney(cost, true)}
                </span>
                {isReworked && (
                  <ReworkButton isDisabled isPrintable={isPrintable} tooltipText="ReWorked Task" />
                )}
                {isEditable &&
                  !isReworked &&
                  (!dealIncludedInCalculation ? null : includedInCalculation ? (
                    <ActionButton
                      className="sidebar-deal__action-button"
                      ariaLabel="Delete entry"
                      color="red"
                      icon={<TrashIcon />}
                      isLoading={isProcessing}
                      onClick={() => handleRemoveDealItem(id)}
                    />
                  ) : (
                    <ActionButton
                      className="sidebar-deal__action-button"
                      ariaLabel="Restore entry"
                      color="green"
                      icon={<RotateIcon />}
                      isLoading={isProcessing}
                      onClick={() => handleRestoreDealItem(id)}
                    />
                  ))}
              </li>
            )
          )}
        </ul>
      </div>
    );
  }
);

export default SidebarDeal;
