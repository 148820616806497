import { doPost } from './axios-config';

import { mapApiToUser } from 'src/adapters';
import { ApiRoute, ErrorText } from 'src/constants';
import type { IApiUser, IUser } from 'src/interfaces';

export async function fetchUserSignIn(
  credential: string,
  onSuccess: (token: string, user: IUser) => void,
  onFail: (error: string) => void
) {
  try {
    const result = await doPost<IApiUser>(ApiRoute.SIGN_IN, { token: credential });

    if (result.headers.authorization) {
      onSuccess(result.headers.authorization.split(' ')[1], mapApiToUser(result.data));
    } else {
      onFail(ErrorText.REQUEST_FAILURE);
    }
  } catch (err: any) {
    console.log(err);
    const errorText = err.response?.data?.message || ErrorText.REQUEST_FAILURE;
    onFail(errorText);
  }
}
