import React from 'react';
import { observer } from 'mobx-react-lite';

import Chip from 'src/components/ui/chip';
import InputDatePicker from 'src/components/ui/inputs/input-date-picker';
import TextError from 'src/components/ui/text-error';

import { DATE_PERIODS } from 'src/constants';
import { isSaturday } from 'src/utils';
import type { DateRangeProps } from './date-range.props';

import './date-range.scss';

const DateRange = observer(({ disableNextDates, isDisabled, store }: DateRangeProps) => {
  return (
    <div className="date-range">
      <InputDatePicker
        disableNextDates={disableNextDates}
        isDisabled={isDisabled}
        label="Date From"
        size="flattened"
        {...store.getInputProps('dateFrom')}
        {...store.getValidationProps('dateFrom')}
        checkDisabledDay={isSaturday}
        onChange={store.handleChangeDateFrom}
      />

      <InputDatePicker
        disableNextDates={disableNextDates}
        isDisabled={isDisabled}
        label="Date To"
        size="flattened"
        {...store.getInputProps('dateTo')}
        {...store.getValidationProps('dateTo')}
        checkDisabledDay={isSaturday}
        onChange={store.handleChangeDateTo}
      />
      <ul className="date-range__chips-list">
        {DATE_PERIODS.map((period) => (
          <li key={period} className="date-range__chips-list-item">
            <Chip
              isActive={period === store.activePeriod}
              isDisabled={isDisabled}
              text={period}
              onClick={() => store.setActivePeriod(period)}
            />
          </li>
        ))}
      </ul>
      {store.isDateValid.textError ? (
        <TextError className="date-range__error" textError={store.isDateValid.textError} />
      ) : null}
    </div>
  );
});

export default DateRange;
