import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import BonusLine from '../bonus-line';
import SidebarDeal from '../sidebar-deal';

import calculationDetailsStore from 'src/stores/calculation-details-store';

import { formatMoney } from 'src/utils';
import type { SidebarDealsProps } from './sidebar-deals.props';

import './sidebar-deals.scss';

const SidebarDeals = observer(
  ({
    calculation: { bonus, fine, total, workorders },
    isEditable,
    isPrintable,
  }: SidebarDealsProps) => {
    const { calculationComment, isProcessing, handleBonusChange, handleFineChange } =
      calculationDetailsStore;

    return (
      <section className="sidebar-deals">
        <h3 className="sidebar-deals__title">Bundles, Products & Services</h3>
        <div className="sidebar-deals__content">
          <div className="sidebar-deals__header">
            <span className="sidebar-deals__header-item">Name</span>
            <span className="sidebar-deals__header-item">Type</span>
            <span className="sidebar-deals__header-item sidebar-deals__header-item_right">
              Hours
            </span>
            <span className="sidebar-deals__header-item sidebar-deals__header-item_right">
              Price
            </span>
            <span className="sidebar-deals__header-item sidebar-deals__header-item_right">
              Rate
            </span>
            <span className="sidebar-deals__header-item sidebar-deals__header-item_right">
              Cost
            </span>
            <span className="sidebar-deals__header-item sidebar-deals__header-item_right">
              Actions
            </span>
          </div>
          {workorders.map((order) => (
            <SidebarDeal
              key={order.id}
              isEditable={isEditable}
              isPrintable={isPrintable}
              order={order}
            />
          ))}
          <div className="sidebar-deals__bonuses">
            <div
              className={classNames(
                'sidebar-deals__bonuses-wrapper',
                isEditable && 'sidebar-deals__bonuses-wrapper_editable'
              )}
            >
              <BonusLine
                isEditable={isEditable}
                isLoading={isProcessing}
                isPrintable={isPrintable}
                label="Bonus"
                value={bonus}
                onChange={handleBonusChange}
              />
              <BonusLine
                isEditable={isEditable}
                isFine
                isLoading={isProcessing}
                isPrintable={isPrintable}
                label="Fine"
                value={fine}
                onChange={handleFineChange}
              />
            </div>
            {calculationComment && (
              <div className="sidebar-deals__comment">
                <h4 className="sidebar-deals__comment-title">Message to contractor</h4>
                <p className="sidebar-deals__comment-text">{calculationComment}</p>
              </div>
            )}
          </div>
          <div className="sidebar-deals__total">
            <span className="sidebar-deals__total-label">Total:</span>
            <span className="sidebar-deals__total-value">{formatMoney(total, true)}</span>
          </div>
        </div>
      </section>
    );
  }
);

export default SidebarDeals;
