import { MutableRefObject, useEffect } from 'react';

import type { TOnKeyUp } from 'src/interfaces';

export default function useKeyUp(
  targetKey: string,
  onKeyUp: TOnKeyUp,
  ref?: MutableRefObject<HTMLElement | null>,
  isListenerStopped: boolean = false
) {
  useEffect(() => {
    const innerRef = ref;

    const handleKeyUp = (evt: KeyboardEvent) => {
      if (evt.key === targetKey) {
        (evt.target as any).blur();
        onKeyUp(evt);
      }
    };

    if (!isListenerStopped && innerRef?.current) {
      innerRef.current.addEventListener('keyup', handleKeyUp);
    } else if (isListenerStopped && innerRef?.current) {
      innerRef.current.removeEventListener('keyup', handleKeyUp);
    }

    return () => {
      if (innerRef?.current) {
        innerRef.current.removeEventListener('keyup', handleKeyUp);
      }
    };
  }, [targetKey, onKeyUp, isListenerStopped, ref]);
}
