import { createContext, ReactNode, useContext, useMemo } from 'react';

import useLoadGsiScript, { UseLoadGsiScriptOptions } from 'src/hooks/use-google-gsi-script';

interface GoogleOAuthContextProps {
  clientId: string;
  scriptLoadedSuccessfully: boolean;
}

const GoogleOAuthContext = createContext<GoogleOAuthContextProps>(null!);

interface GoogleOAuthProviderProps extends UseLoadGsiScriptOptions {
  clientId: string;
  children: ReactNode;
}

function GoogleOAuthProvider({
  children,
  clientId,
  onScriptLoadSuccess,
  onScriptLoadError,
}: GoogleOAuthProviderProps) {
  const scriptLoadedSuccessfully = useLoadGsiScript({
    onScriptLoadSuccess,
    onScriptLoadError,
  });

  const contextValue = useMemo(
    () => ({
      clientId,
      scriptLoadedSuccessfully,
    }),
    [clientId, scriptLoadedSuccessfully]
  );

  return <GoogleOAuthContext.Provider value={contextValue}>{children}</GoogleOAuthContext.Provider>;
}

function useGoogleOAuth() {
  const context = useContext(GoogleOAuthContext);
  if (!context) {
    throw new Error('Google OAuth components must be used within GoogleOAuthProvider');
  }
  return context;
}

export { GoogleOAuthProvider, useGoogleOAuth };
