import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@devopsafs/react-ui-components';

import Loader from 'src/components/common/loader';
import Select from 'src/components/ui/select';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import type { ICommonPopupProps } from 'src/interfaces';

const PopupCreateTeam = observer(({ onCloseButtonClick }: ICommonPopupProps) => {
  const { contractorsList, onCreateTeam } = popupStore.getPopupState(PopupName.CREATE_TEAM);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [firstContractor, setFirstContractor] = useState<string>('');
  const [secondContractor, setSecondContractor] = useState<string>('');

  const firstContractorsOptions = useMemo(
    () => contractorsList.map(({ id, fullName }) => ({ id, value: fullName })),
    [contractorsList]
  );

  const secondContractorsOptions = useMemo(
    () =>
      contractorsList
        .filter(({ id }) => id !== firstContractor)
        .map(({ id, fullName }) => ({ id, value: fullName })),
    [contractorsList, firstContractor]
  );

  const handleCreateButtonClick = async () => {
    setLoading(true);
    const contractorsIds = [
      firstContractor,
      secondContractor && secondContractor !== firstContractor ? secondContractor : undefined,
    ] as const;
    await onCreateTeam(onCloseButtonClick, ...contractorsIds);
    setLoading(false);
  };

  return (
    <>
      <div className="popup__header">
        <p className="popup__title">Create Work Team</p>
        <p className="popup__text">Please select at least one Team member</p>
      </div>

      <Select
        isDisabled={isLoading}
        items={firstContractorsOptions}
        label="Contractor 1"
        size="flattened"
        value={firstContractor}
        withSearch
        onChange={setFirstContractor}
      />
      <Select
        isDisabled={!firstContractor || isLoading}
        isOptional
        items={secondContractorsOptions}
        label="Contractor 2 (optional)"
        size="flattened"
        value={secondContractor}
        withSearch
        onChange={setSecondContractor}
      />

      <div className="popup__buttons">
        <Button
          className="popup__button"
          label="Cancel"
          theme="outlined"
          onClick={onCloseButtonClick}
        />
        <Button
          className="popup__button"
          icon={isLoading && <Loader isInline size="small" />}
          isDisabled={!firstContractor || isLoading}
          label="Create"
          theme="filled"
          onClick={handleCreateButtonClick}
        />
      </div>
    </>
  );
});

export default PopupCreateTeam;
