import { doPost } from './axios-config';

import { ApiRoute } from 'src/constants';
import type { TApiResponse } from 'src/interfaces';

export async function fetchSync(): Promise<TApiResponse> {
  try {
    await doPost<never>(ApiRoute.SYNC, null);

    return { isSuccess: true };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
