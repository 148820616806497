import React from 'react';
import { Button } from '@devopsafs/react-ui-components';

import Loader from 'src/components/common/loader';
import { ReactComponent as TableStubIcon } from 'src/assets/icons/not-found.svg';

import type { TableStubProps } from './table-stub.props';

import './table-stub.scss';

const TableStub = ({ onResetFilter }: TableStubProps) => {
  const [disabledButton, setDisabledButton] = React.useState(false);

  const handleButtonClick = () => {
    setDisabledButton(true);
    onResetFilter?.();
  };

  return (
    <div className="table-stub">
      <TableStubIcon className="table-stub__icon" />

      <div className="table-stub__info">
        <span className="table-stub__title">Nothing to Display</span>
        <span className="table-stub__text">Try changing your filter settings or search term</span>
      </div>

      {onResetFilter && (
        <Button
          className="table-stub__button"
          icon={disabledButton && <Loader isInline size="small" />}
          isDisabled={disabledButton}
          label="Show All"
          size="oversize"
          theme="outlined"
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
};

export default TableStub;
