import { ALL } from './app-common';

export enum CalculationStatus {
  APPROVED = 'approved',
  DECLINED = 'declined',
  WAITING = 'waiting',
}

export const CalculationStatusShown = {
  [CalculationStatus.APPROVED]: 'Approved',
  [CalculationStatus.DECLINED]: 'Declined',
  [CalculationStatus.WAITING]: 'Waiting',
};

const FILTER_STATUS_ORDER = [
  CalculationStatus.WAITING,
  CalculationStatus.APPROVED,
  CalculationStatus.DECLINED,
];

export const CALCULATION_STATUS_FILTER_OPTIONS = [
  { id: '', value: ALL },
  ...FILTER_STATUS_ORDER.map((status) => ({
    id: status,
    value: CalculationStatusShown[status],
  })),
];

export enum OrderIncludedStatus {
  EXCLUDED = 'excluded',
  INCLUDED = 'included',
}
