import { InputMaxLength, InputMinLength, Regex } from 'src/constants';

export const getIsEmailValid = (email: string) =>
  !!email && Regex.EMAIL.test(email) && email.trim().length <= InputMaxLength.EMAIL;

export const getIsNameValid = (value: string) =>
  !!value &&
  value.trim().length >= InputMinLength.NAME &&
  value.trim().length <= InputMaxLength.TEXT;

export const getIsRateNameValid = (value: string) =>
  !!value &&
  value.trim().length >= InputMinLength.RATE_NAME &&
  value.trim().length <= InputMaxLength.TEXT;

export const getIsManHoursValid = (value: string) =>
  !!value && !value.endsWith('.') && Number(value) > 0;

export const getIsPhoneValid = (phone: string) => Regex.PHONE.test(phone);
