import { APP_LOCALSTORAGE_JWT, APP_LOCALSTORAGE_USER } from 'src/constants';

export function getValueFromLocalStorage(
  property: typeof APP_LOCALSTORAGE_USER | typeof APP_LOCALSTORAGE_JWT
) {
  try {
    const data = localStorage.getItem(property);

    return data && property === APP_LOCALSTORAGE_USER ? JSON.parse(data) : data;
  } catch (err) {
    console.log(err);
  }
}
