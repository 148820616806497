import { Navigate, Route } from 'react-router-dom';

import MainLayout from 'src/layouts/main-layout';
import SettingsPage from 'src/pages/settings-page';
import Header from 'src/components/common/header';

import { AppRoute, SETTINGS_SUB_ROUTES } from 'src/constants';

const SETTINGS_PAGE_ROUTES = [AppRoute.SETTINGS_CONTRACTORS, AppRoute.SETTINGS_PARTNERSHIP];

export const SettingsPageRoutes = () => {
  return (
    <>
      <Route>
        {SETTINGS_PAGE_ROUTES.map((route) => (
          <Route
            key={route}
            path={route}
            element={
              <MainLayout children={<SettingsPage />} header={<Header isSettingsHeader />} />
            }
          />
        ))}
        <Route
          element={<Navigate replace to={AppRoute.SETTINGS_CONTRACTORS} />}
          path={`${AppRoute.SETTINGS}/*`}
        />
      </Route>

      <Route>
        {SETTINGS_SUB_ROUTES.map((route) => (
          <Route
            key={route}
            path={route}
            element={
              <MainLayout children={<SettingsPage />} header={<Header isSettingsHeader />} />
            }
          />
        ))}
        <Route
          element={<Navigate replace to={AppRoute.SETTINGS_RATES_BUNDLE} />}
          path={`${AppRoute.SETTINGS_RATES}/*`}
        />
      </Route>
    </>
  );
};
