import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import ExternalLink from 'src/components/common/external-link';
import IconButton from 'src/components/ui/icon-button';
import TooltipTrigger from 'src/components/ui/tooltip-trigger';
import { ReactComponent as AlertIcon } from 'src/assets/icons/alert-icon.svg';
import { ReactComponent as IconNewWindow } from 'src/assets/icons/new-window.svg';

import useKeyUpGlobal from 'src/hooks/use-key-up-global';
import useKeyUpOutside from 'src/hooks/use-key-up-outside';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import { AppRoute, Key, Position } from 'src/constants';
import { getPersonName } from 'src/utils';
import type { ReworkButtonProps } from './rework-button.props';

import './rework-button.scss';

const ReworkButton = ({
  isDisabled,
  isPrintable,
  reworkParentCalculations = [],
  tooltipText,
}: ReworkButtonProps) => {
  const [isShowParentCalculations, setIsShowParentCalculations] = useState(false);
  const isSeveralReworksParents = reworkParentCalculations.length > 1;
  const parentId = reworkParentCalculations[0]?.id ?? '';
  const parentsButtonRef = useRef<HTMLDivElement>(null);
  const isIconButtonDisabled = isDisabled || !parentId || isPrintable;

  const handleHideTooltip = useCallback(() => setIsShowParentCalculations(false), []);

  useOnClickOutside(parentsButtonRef, handleHideTooltip, !isShowParentCalculations);
  useKeyUpOutside(Key.ENTER, parentsButtonRef, handleHideTooltip, !isShowParentCalculations);
  useKeyUpGlobal(Key.ESCAPE, handleHideTooltip, 0, !isShowParentCalculations);

  const handleIconButtonClick = useCallback(() => {
    setIsShowParentCalculations((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isShowParentCalculations) {
      window.addEventListener('scroll', handleHideTooltip, true);
    } else {
      window.removeEventListener('scroll', handleHideTooltip, true);
    }

    return () => {
      window.removeEventListener('scroll', handleHideTooltip, true);
    };
  }, [handleHideTooltip, isShowParentCalculations]);

  return (
    <div className="rework-button" ref={parentsButtonRef}>
      <TooltipTrigger
        shouldShowOnHover={!isPrintable && !isShowParentCalculations}
        text={tooltipText}
        tooltipPosition={Position.LEFT}
      >
        <IconButton
          className={classNames(
            'rework-button__button',
            isIconButtonDisabled && 'rework-button__button_disabled'
          )}
          ariaLabel="Reworked"
          icon={<AlertIcon />}
          isDisabled={isIconButtonDisabled}
          theme="square"
          {...(isSeveralReworksParents
            ? { onClick: handleIconButtonClick }
            : {
                href: `${AppRoute.CONTRACTOR_CALCULATION}/${parentId}`,
                tabIndex: isIconButtonDisabled ? -1 : 0,
                tag: 'link',
                target: '_blank',
              })}
        />
      </TooltipTrigger>
      {isShowParentCalculations && (
        <div className="rework-button__parents">
          {reworkParentCalculations.map(({ id, contractor }) => (
            <ExternalLink
              className="rework-button__parents-link"
              key={id}
              href={`${AppRoute.CONTRACTOR_CALCULATION}/${id}`}
            >
              <span className="rework-button__parents-link-text">{getPersonName(contractor)}</span>
              <IconNewWindow className="rework-button__parents-link-icon" />
            </ExternalLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReworkButton;
