import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import PopupConfirm from '../popup-confirm';
import PopupCreateTeam from '../popup-create-team';
import PopupWarn from '../popup-warn';

import popupStore from 'src/stores/popup-store';

import useKeyUpGlobal from 'src/hooks/use-key-up-global';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import { Key, PopupName } from 'src/constants';
import type { ICommonPopupProps } from 'src/interfaces';
import type { PopupProps } from './popup.props';

import './popup.scss';

const POPUP_COMPONENTS: Record<PopupName, React.FC<ICommonPopupProps>> = {
  [PopupName.CONFIRM]: PopupConfirm,
  [PopupName.CREATE_TEAM]: PopupCreateTeam,
  [PopupName.WARN]: PopupWarn,
};

const Popup = observer(({ popupName }: PopupProps) => {
  const popupRef = React.useRef(null);

  const { className, isVisible, onClose } = popupStore.getPopupState(popupName);

  const closePopup = () => {
    popupStore.hidePopup(popupName);
    onClose?.();
  };

  const { isUnmounting, onAnimationEnd, unmountComponent } = useUnmountAnimation(closePopup);
  useOnClickOutside(popupRef, unmountComponent);
  useKeyUpGlobal(Key.ESCAPE, (evt) => {
    evt.stopPropagation();
    unmountComponent();
  });

  const PopupComponent = POPUP_COMPONENTS[popupName] || null;

  return (
    <div
      className={classNames(
        'popup',
        className,
        isUnmounting && 'popup_hiding',
        `popup_${popupName}`,
        'scrollbar'
      )}
    >
      <div className="popup__wrapper scrollbar" ref={popupRef} onAnimationEnd={onAnimationEnd}>
        {isVisible && <PopupComponent onCloseButtonClick={unmountComponent} />}
      </div>
    </div>
  );
});

export default Popup;
