import { makeAutoObservable } from 'mobx';
import io, { type Socket } from 'socket.io-client';

import { ApiRoute, AppRoute } from 'src/constants';
import { getCurrentRoute } from 'src/utils';
import type { ICalculationsFilter, IStatistics, TStatisticsEventName } from 'src/interfaces';

interface IDisconnectListener {
  id: string;
  cb: (reason: Socket.DisconnectReason) => void;
}

interface IErrorListener {
  id: string;
  cb: (error: Error) => void;
}

interface IStatisticsListener {
  id: string;
  cb: (statistics: IStatistics, skipUpdate: boolean) => void;
}

class SocketStore {
  init(token: string) {
    const currentRoute = getCurrentRoute(window.location.pathname);

    if (currentRoute.includes(AppRoute.PRINT_CONTRACTOR_CALCULATION)) {
      return;
    }

    this.setToken(token);
  }

  isFirstStatisticsLoad: boolean = true;
  statisticsDisconnectListeners: IDisconnectListener[] = [];
  statisticsErrorListeners: IErrorListener[] = [];
  statisticsListeners: IStatisticsListener[] = [];
  statisticsSocket: Socket | null = null;
  token: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setIsFirstStatisticsLoad(isFirst: boolean) {
    this.isFirstStatisticsLoad = isFirst;
  }

  setStatisticsDisconnectListeners(listeners: IDisconnectListener[]) {
    this.statisticsDisconnectListeners = listeners;
  }

  setStatisticsErrorListeners(listeners: IErrorListener[]) {
    this.statisticsErrorListeners = listeners;
  }

  setStatisticsListeners(listeners: IStatisticsListener[]) {
    this.statisticsListeners = listeners;
  }

  addStatisticsListener(listener: IStatisticsListener) {
    if (!this.statisticsListeners.length) {
      this.statisticsListeners.push(listener);
      return;
    }

    const listenerIdx = this.statisticsListeners.findIndex((item) => item.id === listener.id);

    if (listenerIdx === -1) {
      this.statisticsListeners.push(listener);
    } else {
      this.statisticsListeners.splice(listenerIdx, 1, listener);
    }
  }

  removeStatisticsListener(listenerId: string) {
    this.statisticsListeners = this.statisticsListeners.filter((item) => item.id !== listenerId);
  }

  addStatisticsErrorListener(listener: IErrorListener) {
    if (!this.statisticsErrorListeners.length) {
      this.statisticsErrorListeners.push(listener);
      return;
    }

    const listenerIdx = this.statisticsErrorListeners.findIndex((item) => item.id === listener.id);

    if (listenerIdx === -1) {
      this.statisticsErrorListeners.push(listener);
    } else {
      this.statisticsErrorListeners.splice(listenerIdx, 1, listener);
    }
  }

  removeStatisticsErrorListener(listenerId: string) {
    this.statisticsErrorListeners = this.statisticsErrorListeners.filter(
      (item) => item.id !== listenerId
    );
  }

  addStatisticsDisconnectListener(listener: IDisconnectListener) {
    if (!this.statisticsDisconnectListeners.length) {
      this.statisticsDisconnectListeners.push(listener);
      return;
    }

    const listenerIdx = this.statisticsDisconnectListeners.findIndex(
      (item) => item.id === listener.id
    );

    if (listenerIdx === -1) {
      this.statisticsDisconnectListeners.push(listener);
    } else {
      this.statisticsDisconnectListeners.splice(listenerIdx, 1, listener);
    }
  }

  removeStatisticsDisconnectListener(listenerId: string) {
    this.statisticsDisconnectListeners = this.statisticsDisconnectListeners.filter(
      (item) => item.id !== listenerId
    );
  }

  setStatisticsSocket(socket: Socket | null) {
    this.statisticsSocket = socket;
  }

  setToken(token: string) {
    this.token = token;
  }

  createSocket<T, K extends string = 'data'>(url: string, query?: Record<string, any>) {
    const token = this.token;
    if (!token) {
      return;
    }

    const socketOptions = {
      auth: {
        token,
      },
      reconnectionAttempts: 3,
      reconnectionDelay: 1000,
      forceNew: true,
      ...(query && { query }),
    };

    const socket: Socket<{ [key in K]: (data: T) => void }> = io(
      `${getApiBaseUrl()}/${url}`,
      socketOptions
    );

    return socket;
  }

  subscribeToCalculationsStatistics(filter: ICalculationsFilter) {
    this.unsubscribeFromStatistics();

    const filterString = Object.keys(filter).length ? JSON.stringify(filter) : '';
    const socket = this.createSocket<IStatistics, TStatisticsEventName>(
      ApiRoute.WS_CALCULATIONS_STAT,
      filterString ? { filter: filterString } : undefined
    );
    if (!socket) {
      return;
    }

    socket.on('connect', () => {
      console.info('calculations stats ws connected');
    });

    socket.on('connect_error', (error) => {
      console.info('calculations stats connect error', error);
      this.statisticsErrorListeners.forEach((listener) => listener.cb(error));
    });

    socket.on('disconnect', (reason) => {
      console.info('calculations stats ws disconnected');
      this.statisticsDisconnectListeners.forEach((listener) => listener.cb(reason));
    });

    socket.on(filterString ? `data-${filterString}` : 'data', (statistics: IStatistics) => {
      this.statisticsListeners.forEach((listener) =>
        listener.cb(statistics, this.isFirstStatisticsLoad)
      );

      if (this.isFirstStatisticsLoad) {
        this.setIsFirstStatisticsLoad(false);
      }
    });

    this.setStatisticsSocket(socket);
  }

  unsubscribeFromStatistics() {
    if (this.statisticsSocket) {
      this.statisticsSocket.disconnect();
      this.setStatisticsSocket(null);
    }
    this.setIsFirstStatisticsLoad(true);
  }

  disconnect() {
    this.unsubscribeFromStatistics();
  }

  clear() {
    this.disconnect();
    this.setStatisticsDisconnectListeners([]);
    this.setStatisticsErrorListeners([]);
    this.setStatisticsListeners([]);
    this.setToken('');
  }
}

function getApiBaseUrl() {
  try {
    return new URL(window.API_URL).origin;
  } catch {
    console.error('Invalid url in Window.API_URL');
    return window.API_URL;
  }
}

export default new SocketStore();
