import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Input } from '@devopsafs/react-ui-components';

import ActionButton from 'src/components/common/action-button';
import Loader from 'src/components/common/loader';
import Table from 'src/components/common/table';
import TableButton from 'src/components/common/table-button';
import { ReactComponent as SaveIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as CancelIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';

import contractorsStore from 'src/stores/contractors-store';

import { TABLE_CONTRACTORS_COLUMNS, TableType } from 'src/constants';

import './contractors-table.scss';

const ContractorsTable = observer(() => {
  const {
    sortedContractorsList,
    isLoading,
    checkIsEditingForm,
    editForm,
    getInputProps,
    getNewContractorInputProps,
    handleAddContractorClick,
    handleChangeContractorClick,
    handleStatusChange,
    processingContractorId,
    removeForm,
  } = contractorsStore;

  useEffect(() => {
    contractorsStore.init();
  }, []);

  return (
    <>
      <Table
        headerItems={TABLE_CONTRACTORS_COLUMNS}
        rows={[
          {
            id: 'contractors-add-panel',
            items: [
              {
                childComponent: <Input {...getNewContractorInputProps('firstName')} />,
              },
              {
                childComponent: <Input {...getNewContractorInputProps('lastName')} />,
              },
              {
                childComponent: <Input {...getNewContractorInputProps('phone')} />,
              },
              {
                childComponent: <Input {...getNewContractorInputProps('email')} />,
              },
              { text: '' },
              {
                childComponent: (
                  <Button
                    className="contractors-table__add-button"
                    isDisabled={isLoading}
                    label="Add"
                    size="flattened"
                    theme="outlined"
                    width="100%"
                    onClick={handleAddContractorClick}
                  />
                ),
              },
            ],
          },
          ...sortedContractorsList.map(({ id, status, ...otherProps }) => {
            const isEdited = checkIsEditingForm(id);
            const isProcessing = id === processingContractorId;

            return {
              id,
              items: [
                ...(['firstName', 'lastName', 'phone', 'email'] as const).map((field) =>
                  isEdited
                    ? {
                        childComponent: isProcessing ? (
                          <Loader
                            className="small-settings-page-loader"
                            position="left"
                            size="small"
                          />
                        ) : (
                          <Input {...getInputProps(id, field)} />
                        ),
                      }
                    : { text: otherProps[field] }
                ),
                {
                  className: 'contractors-table__actions',
                  childComponent: isEdited ? (
                    <>
                      <ActionButton
                        ariaLabel="Save"
                        color="green"
                        icon={<SaveIcon />}
                        isDisabled={isProcessing}
                        onClick={() => handleChangeContractorClick(id)}
                      />
                      <ActionButton
                        ariaLabel="Cancel"
                        color="red"
                        icon={<CancelIcon />}
                        isDisabled={isProcessing}
                        onClick={() => removeForm(id)}
                      />
                    </>
                  ) : (
                    <ActionButton
                      ariaLabel="Edit"
                      icon={<EditIcon />}
                      isDisabled={isProcessing}
                      onClick={() => editForm(id)}
                    />
                  ),
                },
                {
                  className: 'table-col_with-status-button',
                  childComponent: (
                    <TableButton
                      isDisabled={isProcessing}
                      status={status}
                      onStatusChange={(status) => handleStatusChange(id, status)}
                    />
                  ),
                },
              ],
            };
          }),
        ]}
        tableType={TableType.CONTRACTORS}
      />

      {isLoading && <Loader position="overlay" />}
    </>
  );
});

export default ContractorsTable;
