import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Navbar from 'src/components/common/navbar';
import Sidebar from 'src/components/common/sidebar';

import sidebarStore from 'src/stores/sidebar-store';

import type { MainLayoutProps } from './main-layout.props';

import './main-layout.scss';

const MainLayout = observer(({ children, className, header }: MainLayoutProps) => {
  return (
    <>
      <div className={classNames('main-layout', !header && 'main-layout_headless', className)}>
        <Navbar />
        {header}
        <div className="main-layout__content scrollbar">{children}</div>
      </div>
      {sidebarStore.isShown && sidebarStore.dataId && <Sidebar id={sidebarStore.dataId} />}
    </>
  );
});

export default MainLayout;
