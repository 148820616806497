import { MutableRefObject, useEffect, useState } from 'react';

export default function useDropdownPosition(
  ref: MutableRefObject<HTMLElement | null>,
  externalElementClassName: string,
  dropdownHeight: number
) {
  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');

  useEffect(() => {
    if (ref.current) {
      const refPosition = ref.current.getBoundingClientRect();
      const externalElement = ref.current.closest(externalElementClassName);

      if (externalElement) {
        const externalElementHeight = externalElement.getBoundingClientRect().bottom;
        const isDropdownOverElement =
          externalElementHeight - refPosition.bottom + refPosition.height / 2 < dropdownHeight;

        if (isDropdownOverElement) {
          setDropdownPosition('top');
        }
      }
    }
  }, [dropdownHeight, externalElementClassName, ref]);

  return dropdownPosition;
}
