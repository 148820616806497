import { CalculationStatus } from './calculation-status';

export enum PopupName {
  CONFIRM = 'confirm',
  CREATE_TEAM = 'create-team',
  WARN = 'warn',
}

export const ChangeStatusPopupText = {
  [CalculationStatus.APPROVED]: {
    buttonLabel: 'Approve',
    text: 'Are you sure you want to approve this Invoice',
    title: 'Approve the Invoice?',
  },
  [CalculationStatus.DECLINED]: {
    buttonLabel: 'Decline',
    text: 'Are you sure you want to decline this Invoice',
    title: 'Decline the Invoice?',
  },
  [CalculationStatus.WAITING]: {
    buttonLabel: 'Undone',
    text: 'Are you sure you want to mark this Invoice as Undone? This will change the status of the Invoice to "Waiting"',
    title: 'Mark this Invoice as Undone?',
  },
};
