import React from 'react';

import Tab from 'src/components/ui/tab';
import { ReactComponent as ChartIcon } from 'src/assets/icons/chart.svg';
import { ReactComponent as PieIcon } from 'src/assets/icons/pie-chart.svg';
import { ReactComponent as UserIcon } from 'src/assets/icons/user.svg';

import { AppRoute, SETTINGS_SUB_ROUTES } from 'src/constants';

import './tab-list.scss';

const TABS = [
  { icon: <UserIcon />, title: 'Contractors', link: AppRoute.SETTINGS_CONTRACTORS },
  {
    icon: <ChartIcon />,
    title: 'Rates',
    link: AppRoute.SETTINGS_RATES_BUNDLE,
    listAdditionalActiveRoutes: SETTINGS_SUB_ROUTES,
  },
  { icon: <PieIcon />, title: 'Partnership', link: AppRoute.SETTINGS_PARTNERSHIP },
];

const TabList = () => {
  return (
    <ul className="tab-list">
      {TABS.map((props) => (
        <li key={props.title} className="tab-list__item">
          <Tab {...props} activeTextType="semibold" />
        </li>
      ))}
    </ul>
  );
};

export default TabList;
