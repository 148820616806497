import React from 'react';
import classNames from 'classnames';

import TableRow from '../table-row';
import TableStub from '../table-stub';

import type { TableBodyProps } from './table-body.props';

import './table-body.scss';

function TableBody({ isProcessing, rows, tableType, onResetFilter }: TableBodyProps) {
  const isEmpty = !isProcessing && !rows.length;

  return (
    <tbody
      className={classNames('table-body', `table-body_${tableType}`, isEmpty && 'table-body_empty')}
    >
      {isEmpty ? (
        <tr>
          <td>
            <TableStub onResetFilter={onResetFilter} />
          </td>
        </tr>
      ) : (
        rows.map((row) => <TableRow key={row.id} row={row} tableType={tableType} />)
      )}
    </tbody>
  );
}

export default TableBody;
