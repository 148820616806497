import { useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react-lite';

import FiltersPanel from 'src/components/common/filters-panel';
import Loader from 'src/components/common/loader';
import Table from 'src/components/common/table';
import TableButton from 'src/components/common/table-button';

import calculationDetailsStore from 'src/stores/calculation-details-store';
import calculationsStore from 'src/stores/calculations-store';
import contractorsStore from 'src/stores/contractors-store';
import ratesStore from 'src/stores/rates-store';
import sidebarStore from 'src/stores/sidebar-store';

import { TABLE_MAIN_COLUMNS, TableType } from 'src/constants';
import { formatMoney, getPersonName, getWorkWeekLabel } from 'src/utils';
import type { ITableRow } from 'src/interfaces';

import './main-page.scss';

const TABLE_CONTAINER_ID = 'table-container-id';

const MainPage = observer(() => {
  const {
    hasMore,
    isLoading: isCalculationsLoading,
    itemsByDate,
    processingCalculationsIds,
    clear,
    loadMore,
    resetFilters,
  } = calculationsStore;
  const { isLoading: isContractorsLoading } = contractorsStore;
  const { isLoading: isCatalogLoading } = ratesStore;
  const isLoading = isCalculationsLoading || isContractorsLoading || isCatalogLoading;

  useEffect(() => {
    calculationsStore.init();
    void contractorsStore.loadContractors();
    void ratesStore.loadCatalog();

    return clear;
  }, [clear]);

  const tableRows = useMemo(
    () => getTableRows(itemsByDate, processingCalculationsIds),
    [itemsByDate, processingCalculationsIds]
  );

  return (
    <div className="main-page">
      <FiltersPanel />
      <div className="main-page__content scrollbar scrollbar_horizontal" id={TABLE_CONTAINER_ID}>
        <InfiniteScroll
          dataLength={tableRows.length}
          hasMore={hasMore}
          loader={<Loader />}
          next={loadMore}
          scrollableTarget={TABLE_CONTAINER_ID}
          style={{ height: '100%', overflow: 'inherit' }}
        >
          <Table
            headerItems={TABLE_MAIN_COLUMNS}
            isProcessing={isCalculationsLoading}
            rows={tableRows}
            tableType={TableType.MAIN}
            onResetFilter={resetFilters}
          />
        </InfiniteScroll>
        {isLoading && <Loader position="overlay" />}
      </div>
    </div>
  );
});

function getTableRows(
  itemsByDate: typeof calculationsStore.itemsByDate,
  processingCalculationsIds: typeof calculationsStore.processingCalculationsIds
) {
  return Object.entries(itemsByDate).reduce<ITableRow[]>((rows, [week, calculationsByDate]) => {
    const weekEntries = Object.entries(calculationsByDate);
    const lastWeekDay = weekEntries[0][0];
    rows.push({
      className: 'table-row_week-title',
      id: week,
      items: [
        {
          className: 'table-col_caption',
          text: `Work week: ${getWorkWeekLabel(lastWeekDay)}`,
        },
      ],
    });
    return weekEntries.reduce(
      (rows, [date, calculationsList]) => [
        ...rows,
        {
          id: date,
          items: [
            {
              className: 'table-col_caption',
              text: date,
            },
          ],
        },
        ...calculationsList.map(
          ({ id, addons, bonus, bundles, comments, fine, contractor, status, total }, i) => ({
            className: i % 2 === 1 ? 'table-row_even' : '',
            id,
            items: [
              { text: getPersonName(contractor) },
              { text: bundles.count.toString() },
              { text: formatMoney(bundles.costs) },
              { text: addons.count.toString() },
              { text: formatMoney(addons.costs) },
              { text: formatMoney(bonus) },
              { text: formatMoney(-fine) },
              { isBold: true, text: formatMoney(total) },
              {
                className: 'table-col_with-status-button',
                childComponent: (
                  <TableButton
                    isDisabled={processingCalculationsIds.includes(id)}
                    status={status}
                    onStatusChange={(status) =>
                      calculationDetailsStore.changeCalculationStatus(
                        status,
                        id,
                        comments[0]?.text ?? ''
                      )
                    }
                  />
                ),
              },
            ],
            onClick: sidebarStore.showSidebar,
          })
        ),
      ],
      rows
    );
  }, []);
}

export default MainPage;
