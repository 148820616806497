import { doDelete, doGet, doPatch, doPost } from './axios-config';

import { mapApiToBrigade } from 'src/adapters';
import { ApiRoute } from 'src/constants';
import type { IApiBrigade, IBrigade, ICreateBrigade, TApiResponse, TRates } from 'src/interfaces';

export async function fetchBrigades() {
  try {
    const result = await doGet<IApiBrigade[]>(ApiRoute.BRIGADES);

    return result.data.map(mapApiToBrigade);
  } catch (err: any) {
    console.log(err);
    return [];
  }
}

export async function fetchBrigadeCreate(data: ICreateBrigade): Promise<TApiResponse<IBrigade>> {
  try {
    const result = await doPost<IApiBrigade>(ApiRoute.BRIGADES, data);

    return {
      isSuccess: true,
      data: mapApiToBrigade(result.data),
    };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchBrigadeRatesChange(
  brigadeId: string,
  contractorId: string,
  data: TRates
): Promise<TApiResponse<IBrigade>> {
  try {
    const result = await doPatch<IApiBrigade>(
      ApiRoute.CONTRACTOR_RATE(brigadeId, contractorId),
      data
    );

    return {
      isSuccess: true,
      data: mapApiToBrigade(result.data),
    };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchBrigadeDelete(brigadeId: string): Promise<TApiResponse> {
  try {
    await doDelete<never>(ApiRoute.BRIGADE(brigadeId));
    return { isSuccess: true };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
