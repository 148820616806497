import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, Input } from '@devopsafs/react-ui-components';

import ActionButton from 'src/components/common/action-button';
import Loader from 'src/components/common/loader';
import Table from 'src/components/common/table';
import RatesTopPanel from './components/rates-top-panel';
import { ReactComponent as SaveIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as CancelIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';

import ratesStore from 'src/stores/rates-store';

import { RatesTypeAsRoute, RouteRateType, TABLE_RATES_COLUMNS, TableType } from 'src/constants';
import { formatMoney } from 'src/utils';

import './rates-table.scss';

const RatesTable = observer(() => {
  const { pathname } = useLocation();
  const {
    checkIsEditingForm,
    deleteEntry,
    editForm,
    getNewRateInputProps,
    getInputProps,
    handleAddRateClick,
    handleChangeRateClick,
    isLoading,
    isPanelRateProcessing,
    processingRateId,
    ratesList,
    removeForm,
    setRateType,
  } = ratesStore;
  const ref = useRef<HTMLDivElement | null>(null);
  const path =
    Object.values<string>(RatesTypeAsRoute).find((value) => pathname.split('/').includes(value)) ??
    '';

  const rateType = RouteRateType[path];

  useEffect(() => {
    if (path) {
      void ratesStore.init(rateType);
      setRateType(rateType);
      if (ref.current) {
        ref.current?.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [path, rateType, setRateType]);

  return (
    <div className="rates-table">
      <RatesTopPanel />
      <div ref={ref} className="rates-table__container scrollbar scrollbar_horizontal">
        {(isLoading || isPanelRateProcessing) && <Loader position="overlay" />}
        <Table
          tableType={TableType.RATES}
          headerItems={TABLE_RATES_COLUMNS}
          rows={[
            {
              id: 'rates-add-panel',
              items: [
                {
                  childComponent: <Input {...getNewRateInputProps('name')} />,
                },
                {
                  childComponent: <Input {...getNewRateInputProps('manHours')} />,
                },
                {
                  text: '0',
                },
                {
                  childComponent: (
                    <Button
                      className="rates-table__add-button"
                      label="Add"
                      size="flattened"
                      theme="outlined"
                      onClick={handleAddRateClick}
                      isDisabled={isLoading}
                      width="100%"
                    />
                  ),
                },
              ],
            },
            ...ratesList.map(({ id, price, ...otherProps }) => {
              const isEdited = checkIsEditingForm(id);
              const isProcessing = processingRateId === id;
              return {
                id,
                items: [
                  ...(['name', 'manHours'] as const).map((field) =>
                    isEdited
                      ? {
                          childComponent: isProcessing ? (
                            <Loader
                              className="small-settings-page-loader"
                              position="left"
                              size="small"
                            />
                          ) : (
                            <Input {...getInputProps(id, field)} />
                          ),
                        }
                      : { text: otherProps[field].toString() }
                  ),
                  { text: formatMoney(price) },
                  {
                    className: 'rates-table__actions',
                    childComponent: isEdited ? (
                      <>
                        <ActionButton
                          isLoading={isProcessing}
                          ariaLabel="Save"
                          icon={<SaveIcon />}
                          color="green"
                          onClick={() => handleChangeRateClick(id)}
                        />
                        <ActionButton
                          isLoading={isProcessing}
                          ariaLabel="Cancel"
                          icon={<CancelIcon />}
                          color="red"
                          onClick={() => removeForm(id)}
                        />
                      </>
                    ) : (
                      <>
                        <ActionButton
                          isLoading={isProcessing}
                          ariaLabel="Edit"
                          icon={<EditIcon />}
                          onClick={() => editForm(id)}
                        />

                        <ActionButton
                          isLoading={isProcessing}
                          ariaLabel="Delete"
                          color="red"
                          icon={<TrashIcon />}
                          onClick={() => deleteEntry(id)}
                        />
                      </>
                    ),
                  },
                ],
              };
            }),
          ]}
        />
      </div>
    </div>
  );
});

export default RatesTable;
