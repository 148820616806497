import { CatalogItemLabel } from 'src/constants';
import { formatMoney } from 'src/utils';
import type { DealSelectItemProps } from './deal-select-item.props';

import './deal-select-item.scss';

const DealSelectItem = ({ name, price, type }: DealSelectItemProps) => {
  return (
    <span className="deal-select-item">
      <span className="deal-select-item__title">{name}</span>
      <span className="deal-select-item__subtitle">{CatalogItemLabel[type]}</span>
      <span className="deal-select-item__price">{formatMoney(price, true)}</span>
    </span>
  );
};

export default DealSelectItem;
