import { isEmptyObject, isObject } from './object';

const compareArrays = (a: string[], b: string[]) =>
  a.length === b.length && a.every((element, index) => element.toString() === b[index].toString());

export const getDeepObjectDiff = <T extends Record<string, any>>(
  source: T,
  target: T
): Record<string, any> => {
  const isArrays = Array.isArray(source) && Array.isArray(target);

  if (source === target) {
    return isArrays ? [] : {};
  }

  if (typeof source !== 'object' || typeof target !== 'object') {
    return target;
  }

  if (isArrays) {
    return compareArrays(source, target) ? [] : target;
  }

  return Object.keys(target).reduce(
    (acc, key) => {
      if (key in source) {
        const diff = getDeepObjectDiff(source[key], target[key]);

        if (isEmptyObject(diff) && (isEmptyObject(source[key]) || !isEmptyObject(target[key]))) {
          return acc;
        }

        acc[key] = isObject(source[key]) ? { ...target[key], ...diff } : diff;
        return acc;
      }

      acc[key] = target[key];

      return acc;
    },
    {} as Record<keyof T, any>
  );
};
