import React from 'react';

export default function useUnmountAnimation(unmountHandler = () => {}) {
  const [isUnmounting, setIsUnmounting] = React.useState(false);

  const unmountComponent = React.useCallback(() => {
    setIsUnmounting(true);
  }, [setIsUnmounting]);

  const handleAnimationEnd = React.useCallback(() => {
    if (isUnmounting) {
      setIsUnmounting(false);
      unmountHandler();
    }
  }, [isUnmounting, unmountHandler]);

  return { isUnmounting, onAnimationEnd: handleAnimationEnd, unmountComponent };
}
