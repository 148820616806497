import { makeAutoObservable } from 'mobx';

import popupStore from 'src/stores/popup-store';

import {
  fetchContractorChange,
  fetchContractorChangeStatus,
  fetchContractorCreate,
  fetchContractors,
} from 'src/api';
import {
  ALL,
  ContractorStatus,
  ErrorText,
  InputMaxLength,
  InputType,
  Mask,
  MaskPlaceholder,
  PopupName,
  TableButtonActionLabel,
} from 'src/constants';
import {
  formatApiToPhone,
  formatPhoneToApi,
  getDeepObjectDiff,
  getIsEmailValid,
  getIsNameValid,
  getIsPhoneValid,
  trimSpaces,
} from 'src/utils';
import type { IContractor, IForm } from 'src/interfaces';

const NEW_CONTRACTOR_ID = 'new';

const initialFields = {
  firstName: {
    isValid: true,
    type: InputType.NAME,
    value: '',
  },
  lastName: {
    isValid: true,
    type: InputType.NAME,
    value: '',
  },
  phone: {
    isValid: true,
    type: InputType.PHONE,
    value: '',
  },
  email: {
    isValid: true,
    type: InputType.EMAIL,
    value: '',
  },
};

type TContractorForm = IForm<keyof typeof initialFields>;

type TContractorField = keyof TContractorForm;

class ContractorsStore {
  init() {
    this.clearStore();
    this.loadContractors();
  }

  contractorsList: IContractor[] = [];
  forms: Record<string, TContractorForm> = {
    [NEW_CONTRACTOR_ID]: initialFields,
  };
  isLoading: boolean = false;
  processingContractorId: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setContractorsList(contractorsList: IContractor[]) {
    this.contractorsList = contractorsList;
  }

  setForm(id: string, formData: TContractorForm = initialFields) {
    this.forms[id] = formData;
  }

  setFieldValid(id: string, fieldName: TContractorField, isValid: boolean) {
    const form = this.getForm(id);
    this.setForm(id, { ...form, [fieldName]: { ...form[fieldName], isValid } });
  }

  setFieldValue(id: string, fieldName: TContractorField, value: string) {
    const form = this.getForm(id);
    this.setForm(id, { ...form, [fieldName]: { ...form[fieldName], isValid: true, value } });
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setProcessingContractorId(id: string) {
    this.processingContractorId = id;
  }

  checkIsEditingForm(id: string) {
    return id in this.forms;
  }

  checkIsInputValid(id: string, fieldName: TContractorField) {
    const { type, value } = this.getForm(id)[fieldName];
    switch (type) {
      case InputType.EMAIL:
        return getIsEmailValid(value);
      case InputType.NAME:
        return getIsNameValid(value);
      case InputType.PHONE:
        return getIsPhoneValid(value);
      default:
        return true;
    }
  }

  checkIsFormValid(id: string) {
    return Object.keys(this.getForm(id)).reduce((isFormValid, field) => {
      const isValid = this.checkIsInputValid(id, field);
      if (!isValid) {
        isFormValid = false;
        this.setFieldValid(id, field, false);
      }
      return isFormValid;
    }, true);
  }

  editForm(id: string) {
    const contractor = this.getContractor(id);
    this.setForm(
      id,
      Object.keys(initialFields).reduce<TContractorForm>(
        (form, field) => ({
          ...form,
          [field]: { ...form[field], value: contractor?.[field] ?? '' },
        }),
        initialFields
      )
    );
  }

  getContractor(id: string) {
    return this.contractorsList.find((contractor) => contractor.id === id);
  }

  get contractorsOptions() {
    return [
      { id: '', value: ALL },
      ...this.contractorsList.map((contractor) => ({
        id: contractor.id,
        value: contractor.fullName,
      })),
    ];
  }

  get sortedContractorsList() {
    return [...this.contractorsList].sort((a, b) => {
      if (a.status === ContractorStatus.BLOCKED && b.status !== ContractorStatus.BLOCKED) {
        return 1;
      }

      if (a.status !== ContractorStatus.BLOCKED && b.status === ContractorStatus.BLOCKED) {
        return -1;
      }

      return a.fullName.localeCompare(b.fullName);
    });
  }

  getForm(id: string) {
    return this.forms[id] ?? initialFields;
  }

  getInputLabel(fieldName: TContractorField) {
    const labels = {
      firstName: 'Firstname',
      lastName: 'Lastname',
      phone: 'Phone',
      email: 'Email',
    };
    return labels[fieldName] ?? null;
  }

  getInputProps(id: string, fieldName: TContractorField) {
    const { type, ...publicProperties } = this.getForm(id)[fieldName];
    return {
      ...publicProperties,
      ...(type === InputType.EMAIL && { maxLength: InputMaxLength.EMAIL }),
      ...(type === InputType.NAME && { maxLength: InputMaxLength.TEXT }),
      ...(type === InputType.PHONE && {
        mask: Mask.PHONE,
        maskPlaceholder: MaskPlaceholder.PHONE,
        value: formatApiToPhone(publicProperties.value),
      }),
      isLabelFloating: false,
      label: this.getInputLabel(fieldName),
      size: 'mini' as const,
      onChange: (value: string) => this.setFieldValue(id, fieldName, value),
    };
  }

  getNewContractorInputProps(fieldName: TContractorField) {
    return this.getInputProps(NEW_CONTRACTOR_ID, fieldName);
  }

  clearStore() {
    Object.keys(this.forms).forEach((id) => {
      this.removeForm(id);
    });
  }

  async handleAddContractorClick() {
    if (this.checkIsFormValid(NEW_CONTRACTOR_ID)) {
      const { email, firstName, lastName, phone } = this.getForm(NEW_CONTRACTOR_ID);

      this.setLoading(true);

      const { error, isSuccess } = await fetchContractorCreate(
        trimSpaces({
          email: email.value,
          firstname: firstName.value,
          lastname: lastName.value,
          phone: formatPhoneToApi(phone.value),
        })
      );

      if (isSuccess) {
        this.removeForm(NEW_CONTRACTOR_ID);
        await this.loadContractors();
      } else {
        popupStore.showPopup(PopupName.WARN, {
          title: ErrorText.REQUEST_FAILURE,
          text: error || ErrorText.DEFAULT,
        });
      }

      this.setLoading(false);
    }
  }

  async handleChangeContractorClick(id: string) {
    const contractor = this.getContractor(id);

    if (this.checkIsFormValid(id) && contractor) {
      const { email, firstName, lastName, phone } = this.getForm(id);

      const changedContractor = trimSpaces({
        email: email.value,
        firstname: firstName.value,
        lastname: lastName.value,
        phone: formatPhoneToApi(phone.value),
      });

      const prevContractor = {
        email: contractor.email,
        firstname: contractor.firstName,
        lastname: contractor.lastName,
        phone: formatPhoneToApi(contractor.phone),
      };

      const contractorDiff = getDeepObjectDiff(prevContractor, changedContractor);

      if (!Object.keys(contractorDiff).length) {
        return this.removeForm(id);
      }

      this.setProcessingContractorId(id);

      const { data, error, isSuccess } = await fetchContractorChange(id, contractorDiff);

      if (isSuccess) {
        const updatedContractorIndex = this.contractorsList.findIndex(
          (contractor) => contractor.id === id
        );

        this.setContractorsList([
          ...this.contractorsList.slice(0, updatedContractorIndex),
          data,
          ...this.contractorsList.slice(updatedContractorIndex + 1),
        ]);

        this.removeForm(id);
      } else {
        popupStore.showPopup(PopupName.WARN, {
          title: ErrorText.REQUEST_FAILURE,
          text: error || ErrorText.DEFAULT,
        });
      }

      this.setProcessingContractorId('');
    }
  }

  handleStatusChange(id: string, status: ContractorStatus) {
    const actionLabel = TableButtonActionLabel[status];

    popupStore.showPopup(PopupName.CONFIRM, {
      mainActionLabel: actionLabel,
      secondaryActionLabel: 'Cancel',
      text: `Are you sure you want to ${actionLabel.toLowerCase()} this User`,
      title: `${actionLabel} User?`,
      mainActionHandler: async (onCloseButtonClick?: () => void) => {
        this.setProcessingContractorId(id);
        const { isSuccess, error } = await fetchContractorChangeStatus(id, status);
        if (isSuccess) {
          const updatedContractorIndex = this.contractorsList.findIndex(
            (contractor) => contractor.id === id
          );
          const updatedContractor = { ...this.contractorsList[updatedContractorIndex], status };
          this.setContractorsList([
            ...this.contractorsList.slice(0, updatedContractorIndex),
            updatedContractor,
            ...this.contractorsList.slice(updatedContractorIndex + 1),
          ]);
          onCloseButtonClick?.();
        } else {
          popupStore.showPopup(PopupName.WARN, {
            title: ErrorText.REQUEST_FAILURE,
            text: error || ErrorText.DEFAULT,
          });
        }
        this.setProcessingContractorId('');
      },
    });
  }

  async loadContractors(isForNewBrigade = false) {
    this.setLoading(true);

    const contractorsList = await fetchContractors(isForNewBrigade);
    this.setContractorsList(contractorsList);

    this.setLoading(false);
  }

  removeForm(id: string) {
    if (id === NEW_CONTRACTOR_ID) {
      this.setForm(NEW_CONTRACTOR_ID);
    } else {
      delete this.forms[id];
    }
  }
}

export default new ContractorsStore();
