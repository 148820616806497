import { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import Select from 'src/components/ui/select';
import DealSelectItem from './components/deal-select-item';

import { CatalogItemLabel } from 'src/constants';
import { getMainCurrency } from 'src/utils';
import type { DealSelectProps, IDealSelectOption } from './deal-select.props';

import './deal-select.scss';

const DealSelect = ({
  className,
  isDisabled,
  isLoading,
  items,
  label,
  value,
  onChange,
}: DealSelectProps) => {
  const selectItems = useMemo(
    () =>
      items.map((item) => ({
        id: item.id,
        value: item.name,
        content: <DealSelectItem {...item} />,
      })),
    [items]
  );

  const filterFn = useCallback(
    (value: string, options: IDealSelectOption[]) => {
      const searchWord = value.toLowerCase().trim();
      if (!searchWord) {
        return options;
      }

      return options.filter(({ id }) => {
        const item = items.find((item) => item.id === id);
        if (!item) {
          return false;
        }

        return (
          item.name.toLowerCase().includes(searchWord) ||
          CatalogItemLabel[item.type]?.toLowerCase().includes(searchWord) ||
          getMainCurrency(item.price).toString().toLowerCase().includes(searchWord)
        );
      });
    },
    [items]
  );

  return (
    <Select<IDealSelectOption>
      className={classNames('deal-select', className)}
      isDisabled={isDisabled}
      isLabelFloating={false}
      isLoading={isLoading}
      items={selectItems}
      label={label}
      size="mini"
      value={value}
      withSearch
      filterFn={filterFn}
      onChange={onChange}
    />
  );
};

export default memo(DealSelect);
