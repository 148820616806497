import { doDelete, doGet, doPatch, doPost } from './axios-config';

import { mapApiToCatalogItem } from 'src/adapters';
import { ApiRoute, CatalogItemType } from 'src/constants';
import type {
  IApiCatalogItem,
  ICatalogItem,
  TApiResponse,
  TChangeApiCatalogItem,
  TCreateApiCatalogItem,
} from 'src/interfaces';

export async function fetchCatalogItems(type?: CatalogItemType) {
  try {
    const params = {
      filter: JSON.stringify({
        type,
      }),
    };

    const result = await doGet<IApiCatalogItem[]>(ApiRoute.CATALOG, type ? { params } : undefined);

    return result.data.map(mapApiToCatalogItem);
  } catch (err) {
    console.log(err);
    return [];
  }
}

export async function fetchCatalogItemCreate(
  data: TCreateApiCatalogItem
): Promise<TApiResponse<ICatalogItem>> {
  try {
    const result = await doPost<IApiCatalogItem>(ApiRoute.CATALOG, data);

    return {
      isSuccess: true,
      data: mapApiToCatalogItem(result.data),
    };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchCatalogItemDelete(id: string): Promise<TApiResponse> {
  try {
    await doDelete(ApiRoute.CATALOG_ITEM(id));

    return {
      isSuccess: true,
    };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}

export async function fetchCatalogItemChange(
  id: string,
  data: TChangeApiCatalogItem
): Promise<TApiResponse<ICatalogItem>> {
  try {
    const result = await doPatch(ApiRoute.CATALOG_ITEM(id), data);

    return {
      isSuccess: true,
      data: mapApiToCatalogItem(result.data),
    };
  } catch (err: any) {
    console.log(err);
    return { isSuccess: false, error: err.response?.data?.message };
  }
}
