import React from 'react';
import { observer } from 'mobx-react-lite';
import { Input } from '@devopsafs/react-ui-components';

import ActionButton from 'src/components/common/action-button';
import Loader from 'src/components/common/loader';
import Table from 'src/components/common/table';
import { ReactComponent as SaveIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as CancelIcon } from 'src/assets/icons/cross.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from 'src/assets/icons/trash.svg';

import partnershipStore from 'src/stores/partnership-store';

import { LIST_FIELD_RATES, TABLE_BRIGADE_COLUMNS, TableType } from 'src/constants';
import type { BrigadeTableProps } from './brigade-table.props';

import './brigade-table.scss';

const BrigadeTable = observer(({ brigadeName, brigade }: BrigadeTableProps) => {
  const {
    processingBrigadeId,
    removeTeam,
    getInputProps,
    checkIsEditingForm,
    checkIsEditingDisable,
    editForm,
    changeTeamRates,
    removeForm,
  } = partnershipStore;

  const { id: brigadeId, contractors } = brigade;

  const isProcessing = processingBrigadeId === brigadeId;

  return (
    <div className="brigade-table">
      <div className="brigade-table__heading">
        <span className="brigade-table__contractors">{brigadeName}</span>
        <ActionButton
          ariaLabel="Delete Team"
          color="red"
          icon={<RemoveIcon />}
          isLoading={isProcessing}
          onClick={() => removeTeam(brigadeId)}
        />
      </div>

      <Table
        headerItems={TABLE_BRIGADE_COLUMNS}
        tableType={TableType.BRIGADE}
        rows={contractors.map((contractor) => {
          const isEditing = checkIsEditingForm(brigadeId, contractor.id);
          const editingIsDisabled =
            contractors.length === 1 || checkIsEditingDisable(brigadeId, contractor.id);

          return {
            id: contractor.id,
            items: [
              { text: `${contractor.firstName} ${contractor.lastName}` },
              ...LIST_FIELD_RATES.map((field) =>
                isEditing
                  ? {
                      childComponent: isProcessing ? (
                        <Loader
                          className="small-settings-page-loader"
                          position="left"
                          size="small"
                        />
                      ) : (
                        <Input {...getInputProps(brigadeId, contractor.id, field)} />
                      ),
                    }
                  : { text: contractor.rates[field].toString() }
              ),
              {
                className: 'brigade-table__actions',
                childComponent: isEditing ? (
                  <>
                    <ActionButton
                      ariaLabel="Save"
                      color="green"
                      icon={<SaveIcon />}
                      isLoading={isProcessing}
                      onClick={() => changeTeamRates(brigadeId, contractor.id)}
                    />
                    <ActionButton
                      ariaLabel="Cancel"
                      color="red"
                      icon={<CancelIcon />}
                      isLoading={isProcessing}
                      onClick={() => removeForm(brigadeId)}
                    />
                  </>
                ) : (
                  <ActionButton
                    ariaLabel="Edit"
                    icon={<EditIcon />}
                    isDisabled={editingIsDisabled}
                    isLoading={isProcessing}
                    onClick={() => editForm(brigadeId, contractor.id)}
                  />
                ),
              },
            ],
          };
        })}
      />
    </div>
  );
});

export default BrigadeTable;
