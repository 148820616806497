import React from 'react';

import type { FilterProps } from './filter.props';

import './filter.scss';

const Filter = ({ children, title }: FilterProps) => (
  <div className="filter">
    <h2 className="filter__title">{title}</h2>
    {children}
  </div>
);

export default Filter;
