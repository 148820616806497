import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import socketStore from 'src/stores/socket-store';

import type { IStatistics } from 'src/interfaces';

const SOCKET_LISTENER_ID = uuidv4();

class StatisticsStore {
  init() {
    socketStore.addStatisticsErrorListener({
      id: SOCKET_LISTENER_ID,
      cb: this.handleSocketError,
    });

    socketStore.addStatisticsDisconnectListener({
      id: SOCKET_LISTENER_ID,
      cb: this.handleSocketDisconnect,
    });

    socketStore.addStatisticsListener({
      id: SOCKET_LISTENER_ID,
      cb: this.setStatistics,
    });
  }

  statistics: IStatistics | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setStatistics(statistics: IStatistics | null) {
    this.statistics = statistics || null;
  }

  handleSocketDisconnect() {
    this.setStatistics(null);
  }

  handleSocketError() {
    this.setStatistics(null);
  }

  clear() {
    this.setStatistics(null);
    socketStore.removeStatisticsDisconnectListener(SOCKET_LISTENER_ID);
    socketStore.removeStatisticsErrorListener(SOCKET_LISTENER_ID);
    socketStore.removeStatisticsListener(SOCKET_LISTENER_ID);
  }
}

export default new StatisticsStore();
