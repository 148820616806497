import { memo, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import Loader from 'src/components/common/loader';
import Label from 'src/components/ui/label';
import SelectSearch from './components/select-search';
import { ReactComponent as Arrow } from 'src/assets/icons/chevron-down.svg';

import useOnClickOutside from 'src/hooks/use-on-click-outside';
import { MAX_SELECT_ITEMS_COUNT_WITHOUT_SEARCH } from 'src/constants';
import type { IOption } from 'src/interfaces';
import type { SelectProps } from './select.props';

import './select.scss';

function Select<T extends IOption>({
  className,
  isDisabled,
  isLabelFloating = true,
  isLoading,
  isOptional,
  isValid = true,
  items,
  label,
  size = 'regular',
  value,
  withSearch,
  filterFn,
  onChange,
}: SelectProps<T>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputVal, setInputVal] = useState<string>('');
  const [isSearchFocus, setIsSearchFocus] = useState<boolean>(false);
  const isSearch = withSearch && items.length > MAX_SELECT_ITEMS_COUNT_WITHOUT_SEARCH;
  const selectRef = useRef<HTMLDivElement | null>(null);

  const filteredItems = useMemo(
    () =>
      filterFn
        ? filterFn(inputVal, items)
        : items.filter(({ value = '' }) =>
            value.toLowerCase().includes(inputVal.toLowerCase().trim())
          ),
    [filterFn, inputVal, items]
  );

  useOnClickOutside(selectRef, () => setIsOpen(false));
  useEffect(() => {
    if (!isOpen && isSearch) {
      setInputVal('');
      setIsSearchFocus(false);
    }
  }, [isOpen, isSearch]);

  const handleClick = () => setIsOpen(!isOpen);

  const handleChange = (item: T | null) => {
    onChange(item?.id ?? '', item);
    setIsOpen(false);
  };

  const isSelectDisabled = isDisabled || isLoading || items.length === 0;

  const currentValueLabel = useMemo(() => {
    return items.find((item) => item.id === value)?.value || '';
  }, [items, value]);

  const selectClasses = classNames('select', `select_${size}`, className, {
    select_disabled: isSelectDisabled,
    select_empty: !value,
    select_label: !!label,
    select_open: isOpen,
  });

  const labelClasses = classNames('select__label', {
    label_placeholder: (!isOpen || !isLabelFloating) && !currentValueLabel,
    label_mini: size === 'mini',
    label_hidden: currentValueLabel && !isLabelFloating,
  });

  return (
    <div className={selectClasses} ref={selectRef}>
      <button
        className={classNames('select__button', !isValid && 'select__button_invalid')}
        disabled={isSelectDisabled}
        type="button"
        onClick={handleClick}
      >
        {label && <Label className={labelClasses} isSpan label={label} />}
        <span className="select__value" dir="auto">
          {currentValueLabel}
        </span>
        {isLoading && !isDisabled ? (
          <Loader isInline size="small" />
        ) : (
          <Arrow className="select__icon select__icon_arrow" />
        )}
      </button>
      {isOpen && (
        <div
          className={classNames('select__dropbox scrollbar', isSearch && 'select__dropbox_search')}
        >
          {isSearch && (
            <SelectSearch
              inputVal={inputVal}
              isSearchFocus={isSearchFocus}
              setInputVal={setInputVal}
              setIsSearchFocus={setIsSearchFocus}
            />
          )}

          <div className="select__dropbox-container">
            {isOptional && (
              <button className="select__dropbox-item" onClick={() => handleChange(null)}>
                -
              </button>
            )}
            {filteredItems.map((item) => {
              const classes = classNames('select__dropbox-item', {
                'select__dropbox-item_active': item.id === value,
                'select__dropbox-item_empty': !item.value,
              });
              return (
                <button
                  className={classes}
                  key={item.id}
                  dir="auto"
                  onClick={() => handleChange(item)}
                >
                  {item.content ?? item.value}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const typedMemo: <T>(component: T) => T = memo;
export default typedMemo(Select);
