export const ALL = 'All';
export const PUBLIC_URL = process.env.PUBLIC_URL || '';

export const CONFIG_REQUIRED_PARAMS: (keyof Window)[] = ['API_URL', 'GOOGLE_OAUTH_CLIENT_ID'];
export const CONFIG_REQUIRED_PARAMS_MAPPED = CONFIG_REQUIRED_PARAMS.map((item) => ({
  name: item,
  value: window[item],
}));

export const CONTRACTOR_CALCULATION_GET_PARAM = 'contractor_calculation_id';
export const MAX_SELECT_ITEMS_COUNT_WITHOUT_SEARCH = 7;
