import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Button } from '@devopsafs/react-ui-components';

import Loader from 'src/components/common/loader';
import SidebarDeals from './components/sidebar-deals';
import SidebarHeader from './components/sidebar-header';

import calculationDetailsStore from 'src/stores/calculation-details-store';
import sidebarStore from 'src/stores/sidebar-store';

import useKeyUpGlobal from 'src/hooks/use-key-up-global';
import useUnmountAnimation from 'src/hooks/use-unmount-animation';
import { CalculationStatus, Key } from 'src/constants';
import { getPersonName, getTableRowId } from 'src/utils';
import type { SidebarProps } from './sidebar.props';

import './sidebar.scss';

const Sidebar = observer(({ id, isExternal, isPrintable }: SidebarProps) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { hideSidebar } = sidebarStore;
  const { calculation, isLoading, isProcessing, changeCalculationStatus } = calculationDetailsStore;
  const { isUnmounting, onAnimationEnd, unmountComponent } = useUnmountAnimation(hideSidebar);
  const isEditable = calculation?.status === CalculationStatus.WAITING && !isPrintable;
  const isUndoneButton = calculation?.status !== CalculationStatus.WAITING && !isPrintable;

  useEffect(() => {
    if (!isExternal && !isLoading) {
      sidebarRef.current?.focus();
    }
  }, [isExternal, isLoading]);

  useKeyUpGlobal(
    Key.ESCAPE,
    () => {
      unmountComponent();
      (document.querySelector(`#${getTableRowId(id)}`) as HTMLTableRowElement)?.focus();
    },
    0,
    isExternal
  );

  return (
    <div
      className={classNames(
        'sidebar',
        isExternal && 'sidebar_external',
        isPrintable && 'sidebar_printable',
        isUnmounting && 'sidebar_hide'
      )}
      ref={sidebarRef}
      tabIndex={isPrintable ? -1 : 0}
    >
      {!isExternal && (
        <div
          className="sidebar__shadow"
          onAnimationEnd={onAnimationEnd}
          onClick={unmountComponent}
        />
      )}
      <div className={classNames('sidebar__content', isPrintable && 'sidebar__content_printable')}>
        {isLoading || !calculation ? (
          <Loader position={isExternal ? 'fixed' : 'overlay'} />
        ) : (
          <div className="sidebar__content-wrapper">
            <SidebarHeader
              contractorCalculationId={id}
              date={calculation.date}
              isExternal={isExternal}
              isPrintable={isPrintable}
              status={calculation.status}
              title={getPersonName(calculation.contractor)}
              onCancelBtnClick={unmountComponent}
            />
            <div className="sidebar__content-body  scrollbar">
              <SidebarDeals
                calculation={calculation}
                isEditable={isEditable}
                isPrintable={isPrintable}
              />
              {isEditable && (
                <div className="sidebar__actions">
                  <Button
                    icon={isProcessing && <Loader isInline size="small" />}
                    isDisabled={isProcessing}
                    label="Decline"
                    size="oversize"
                    theme="outlined"
                    onClick={() => changeCalculationStatus(CalculationStatus.DECLINED)}
                  />
                  <Button
                    icon={isProcessing && <Loader isInline size="small" />}
                    isDisabled={isProcessing}
                    label="Approve"
                    size="oversize"
                    theme="filled"
                    onClick={() => changeCalculationStatus(CalculationStatus.APPROVED)}
                  />
                </div>
              )}
              {isUndoneButton && (
                <div className="sidebar__actions sidebar__actions_single-button">
                  <Button
                    icon={isProcessing && <Loader isInline size="small" />}
                    isDisabled={isProcessing}
                    label="Undone"
                    size="oversize"
                    theme="outlined"
                    onClick={() => changeCalculationStatus(CalculationStatus.WAITING)}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Sidebar;
