export const ApiDateFormat = 'YYYY-MM-DD';

export const LocaleFormat = {
  en: 'MM/DD/YYYY',
  he: 'DD.MM.YYYY',
};

export const DateFormat = {
  MONTH_DAY: 'MMM DD',
  FULL: 'D MMM YYYY',
  FULL_WITH_WEEK_DAY: 'ddd, DD MMM YYYY',
};

export const TimeFormat = {
  DUAL: 'HH:mm',
};

export const flatpickrLocaleFormat = {
  he: 'd.m.Y',
};

export const DateError = {
  DEFAULT: 'The date is not valid',
  START_DATE_IS_GREATER_THAN_END_DATE: 'Start date is greater than end date',
};

export enum DatePeriod {
  DAY = 'Day',
  MONTH = 'Month',
  WEEK = 'Week',
}

export const DATE_PERIODS = [DatePeriod.DAY, DatePeriod.WEEK, DatePeriod.MONTH] as const;

export const DEFAULT_PERIOD = DatePeriod.DAY;
