import React from 'react';
import { observer } from 'mobx-react-lite';

import statisticsStore from 'src/stores/statistics-store';

import { formatMoney } from 'src/utils';

import './financials.scss';

const Financials = observer(() => {
  const { waiting, approved, declined } = statisticsStore.statistics ?? {};

  return (
    <div className="financials">
      {[
        { label: 'Waiting', value: waiting },
        { label: 'Approved', value: approved },
        { label: 'Declined', value: declined },
      ].map(({ label, value }) => (
        <div className="financials__info" key={label}>
          <span className="financials__info-name">{label}:</span>
          <span className="financials__info-value">
            {typeof value === 'undefined' ? '-' : formatMoney(value, true)}
          </span>
        </div>
      ))}
    </div>
  );
});

export default Financials;
