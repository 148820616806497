import React from 'react';
import classNames from 'classnames';

import type { ChipProps } from './chip.props';

import './chip.scss';

const Chip = ({ isActive, isDisabled, text, onClick }: ChipProps) => {
  const chipClasses = classNames('chip', isActive && 'chip_active', isDisabled && 'chip_disabled');

  return (
    <button className={chipClasses} disabled={isDisabled} onClick={onClick}>
      {text}
    </button>
  );
};

export default Chip;
