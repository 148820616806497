import type {
  IApiBrigade,
  IApiBrigadeContractor,
  IBrigade,
  IBrigadeContractor,
} from 'src/interfaces';

const mapApiToBrigadeContractor = ({
  firstname,
  lastname,
  ...rest
}: IApiBrigadeContractor): IBrigadeContractor => {
  return {
    ...rest,
    firstName: firstname,
    lastName: lastname,
  };
};

export const mapApiToBrigade = ({ contractors, ...rest }: IApiBrigade): IBrigade => {
  return {
    ...rest,
    contractors: contractors.map(mapApiToBrigadeContractor),
  };
};
