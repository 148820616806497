import { PUBLIC_URL } from 'src/constants';

export const getCurrentRoute = (pathName: string) => {
  try {
    return pathName.replace(new RegExp(`^${PUBLIC_URL}(.*?)/?$`), '$1') || '/';
  } catch (e) {
    console.log(e);
    return pathName;
  }
};
