import React from 'react';

import type { TopPanelProps } from './top-panel.props';

import './top-panel.scss';

function TopPanel({ children, subtitle = '', title }: TopPanelProps) {
  return (
    <div className="top-panel">
      <div className="top-panel__intro">
        <h1 className="top-panel__title">{title}</h1>
        {subtitle && <p className="top-panel__subtitle">{subtitle}</p>}
      </div>
      {children}
    </div>
  );
}

export default TopPanel;
