import React from 'react';
import classNames from 'classnames';

import type { ExternalLinkProps } from './external-link.props';

import './external-link.scss';

const ExternalLink = ({
  children,
  className,
  ariaLabel,
  href,
  isDecorated,
  ...props
}: ExternalLinkProps) => (
  <a
    className={classNames('external-link', isDecorated && 'external-link_decorated', className)}
    aria-label={ariaLabel}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    {...props}
  >
    {children}
  </a>
);

export default ExternalLink;
