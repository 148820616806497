import React from 'react';
import classNames from 'classnames';

import { ReactComponent as BlockIcon } from 'src/assets/icons/block.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';

import type { TableButtonDropdownProps } from './table-button-dropdown.props';

import './table-button-dropdown.scss';

const TableButtonDropdown = ({
  isDisabled,
  mainLabel,
  position,
  secondLabel,
  onMainAction,
  onSecondAction,
}: TableButtonDropdownProps) => (
  <div
    className={classNames('table-button-dropdown', `table-button-dropdown_${position}`)}
    onClick={(evt) => evt.stopPropagation()}
  >
    <div className="table-button-dropdown__actions">
      {mainLabel && (
        <button
          className={classNames(
            'table-button-dropdown__action',
            `table-button-dropdown__action_active`,
            isDisabled && 'table-button-dropdown__action_disabled'
          )}
          disabled={isDisabled}
          onClick={onMainAction}
        >
          <span className="table-button-dropdown__action-icon">
            <CheckIcon />
          </span>
          <span className="table-button-dropdown__action-label">{mainLabel}</span>
        </button>
      )}

      {secondLabel && (
        <button
          className={classNames(
            'table-button-dropdown__action',
            `table-button-dropdown__action_blocked`,
            isDisabled && 'table-button-dropdown__action_disabled'
          )}
          disabled={isDisabled}
          onClick={onSecondAction}
        >
          <span className="table-button-dropdown__action-icon">
            <BlockIcon />
          </span>
          <span className="table-button-dropdown__action-label">{secondLabel}</span>
        </button>
      )}
    </div>
  </div>
);

export default TableButtonDropdown;
