import React from 'react';
import { useLocation } from 'react-router-dom';

import ContractorsTable from './components/contractors-table';
import PartnershipTable from './components/partnership-table';
import RatesTable from './components/rates-table';
import TabList from './components/tab-list';

import { SettingsType } from 'src/constants';

import './settings-page.scss';

const SettingsPage = () => {
  const { pathname } = useLocation();
  const path =
    Object.values<string>(SettingsType).find((value) => pathname.split('/').includes(value)) ?? '';

  const getSettingsTab = (path: string) => {
    return {
      [SettingsType.CONTRACTORS]: <ContractorsTable />,
      [SettingsType.RATES]: <RatesTable />,
      [SettingsType.PARTNERSHIP]: <PartnershipTable />,
    }[path];
  };

  return (
    <div className="settings-page">
      <nav className="settings-page__tabs">
        <TabList />
      </nav>
      <div className="settings-page__content scrollbar scrollbar_horizontal">
        {getSettingsTab(path)}
      </div>
    </div>
  );
};

export default SettingsPage;
