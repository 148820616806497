import { makeAutoObservable } from 'mobx';

import { CONTRACTOR_CALCULATION_GET_PARAM } from 'src/constants';
import { addSearchParam, getSearchParam, removeSearchParam } from 'src/utils';

class SidebarStore {
  init() {
    const contractorCalculationId = getSearchParam(CONTRACTOR_CALCULATION_GET_PARAM);
    if (contractorCalculationId) {
      this.dataId = contractorCalculationId;
      this.isShown = true;
    }
  }

  dataId: string = '';
  isShown: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.init();
  }

  setDataId(id: string) {
    this.dataId = id;
  }

  setIsShown(isShown: boolean) {
    this.isShown = isShown;
  }

  hideSidebar() {
    this.setIsShown(false);
    this.setDataId('');
    removeSearchParam(CONTRACTOR_CALCULATION_GET_PARAM);
  }

  showSidebar(id: string) {
    this.setDataId(id);
    this.setIsShown(true);
    addSearchParam(CONTRACTOR_CALCULATION_GET_PARAM, id);
  }
}

export { SidebarStore };

export default new SidebarStore();
