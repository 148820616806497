import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Button } from '@devopsafs/react-ui-components';

import DateRange from 'src/components/common/date-range';
import Loader from 'src/components/common/loader';
import Select from 'src/components/ui/select';
import Filter from './components/filter';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download.svg';
import { ReactComponent as RotateIcon } from 'src/assets/icons/rotate-clockwise.svg';

import calculationsStore from 'src/stores/calculations-store';
import contractorsStore from 'src/stores/contractors-store';
import filtersStore from 'src/stores/filters-store';

import { CALCULATION_STATUS_FILTER_OPTIONS } from 'src/constants';
import type { FiltersPanelProps } from './filters-panel.props';

import './filters-panel.scss';

const FiltersPanel = observer(({ className }: FiltersPanelProps) => {
  const {
    dateRangeStore,
    selectedCalculationStatus,
    selectedContractor,
    setSelectedCalculationStatus,
    setSelectedContractor,
  } = filtersStore;
  const { contractorsOptions, isLoading: isContractorsLoading } = contractorsStore;
  const {
    isDownloading,
    isLoading: isCalculationsLoading,
    isSyncing,
    items: calculations,
    exportCalculations,
    handleSyncButtonClick,
  } = calculationsStore;
  const isActionsDisabled =
    isContractorsLoading || isCalculationsLoading || isDownloading || isSyncing;

  return (
    <section className={classNames('filters-panel', className)}>
      <div className="filters-panel__wrapper  scrollbar">
        <div className="filters-panel__main-content">
          <Filter title="Dates">
            <DateRange isDisabled={isContractorsLoading} store={dateRangeStore} />
          </Filter>
          <Filter title="Contractor">
            <Select
              isDisabled={isContractorsLoading}
              items={contractorsOptions}
              label="Contractor"
              size="flattened"
              value={selectedContractor}
              withSearch
              onChange={setSelectedContractor}
            />
          </Filter>
          <Filter title="Status">
            <Select
              isDisabled={isContractorsLoading}
              items={CALCULATION_STATUS_FILTER_OPTIONS}
              label="Status"
              size="flattened"
              value={selectedCalculationStatus}
              onChange={setSelectedCalculationStatus as (val: string) => void}
            />
          </Filter>
        </div>
        <div className="filters-panel__actions">
          <Button
            icon={isSyncing ? <Loader isInline size="small" /> : <RotateIcon />}
            isDisabled={isActionsDisabled}
            label="Sync Now"
            theme="outlined"
            onClick={handleSyncButtonClick}
          />
          <Button
            icon={isDownloading ? <Loader isInline size="small" /> : <DownloadIcon />}
            isDisabled={isActionsDisabled || !calculations.length}
            label="Export to XLS"
            theme="outlined"
            onClick={exportCalculations}
          />
        </div>
      </div>
    </section>
  );
});

export default FiltersPanel;
