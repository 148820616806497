import classNames from 'classnames';

import { CalculationStatusShown } from 'src/constants';
import type { StatusProps } from './status.props';

import './status.scss';

const Status = ({ isPrintable = false, type }: StatusProps) => {
  const classes = classNames('status', `status_${type}`, isPrintable && 'status_printable');

  return <span className={classes}>{CalculationStatusShown[type]}</span>;
};

export default Status;
