import { useEffect, useRef, useState } from 'react';

export interface UseLoadGsiScriptOptions {
  onScriptLoadSuccess?: () => void;
  onScriptLoadError?: () => void;
}

export default function useLoadGsiScript(options: UseLoadGsiScriptOptions = {}): boolean {
  const { onScriptLoadSuccess, onScriptLoadError } = options;
  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false);
  const onScriptLoadSuccessRef = useRef(onScriptLoadSuccess);
  const onScriptLoadErrorRef = useRef(onScriptLoadError);

  onScriptLoadSuccessRef.current = onScriptLoadSuccess;
  onScriptLoadErrorRef.current = onScriptLoadError;

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client?hl=en';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.onload = () => {
      setScriptLoadedSuccessfully(true);
      onScriptLoadSuccessRef.current?.();
    };
    scriptTag.onerror = () => {
      setScriptLoadedSuccessfully(false);
      onScriptLoadErrorRef.current?.();
    };

    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  return scriptLoadedSuccessfully;
}
