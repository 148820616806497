export enum CatalogItemType {
  BUNDLE = 'bundle',
  PRODUCT = 'product',
  SERVICE = 'service',
}

export const CatalogItemLabel = {
  [CatalogItemType.BUNDLE]: 'Bundle',
  [CatalogItemType.PRODUCT]: 'Product',
  [CatalogItemType.SERVICE]: 'Service',
};
