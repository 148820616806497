export enum AppRoute {
  CONTRACTORS_ACCOUNTING = '/contractors-accounting',
  CONTRACTOR_CALCULATION = '/contractor-calculation',
  LOGIN = '/login',
  PRINT_CONTRACTOR_CALCULATION = '/print/contractor-calculation',
  SETTINGS = '/settings',
  SETTINGS_CONTRACTORS = '/settings/contractors',
  SETTINGS_PARTNERSHIP = '/settings/partnership',
  SETTINGS_RATES = '/settings/rates',
  SETTINGS_RATES_BUNDLE = '/settings/rates/bundles',
  SETTINGS_RATES_PRODUCTS = '/settings/rates/products',
  SETTINGS_RATES_SERVICES = '/settings/rates/services',
}

export const SETTINGS_SUB_ROUTES = [
  AppRoute.SETTINGS_RATES_BUNDLE,
  AppRoute.SETTINGS_RATES_PRODUCTS,
  AppRoute.SETTINGS_RATES_SERVICES,
];

export const LIST_ADDITIONAL_ACTIVE_SETTINGS_ROUTES = [
  ...SETTINGS_SUB_ROUTES,
  AppRoute.SETTINGS_CONTRACTORS,
  AppRoute.SETTINGS_RATES,
  AppRoute.SETTINGS_PARTNERSHIP,
];
