import { CatalogItemType } from 'src/constants';

export const LIST_FIELD_RATES = Object.values(CatalogItemType);

export const RatesTypeAsRoute = {
  BUNDLES: 'bundles',
  SERVICES: 'services',
  PRODUCTS: 'products',
};

export const RatesTypeShow = {
  [CatalogItemType.BUNDLE]: 'Bundles',
  [CatalogItemType.SERVICE]: 'Services',
  [CatalogItemType.PRODUCT]: 'Products',
};

export const RouteRateType: Record<string, CatalogItemType> = {
  [RatesTypeAsRoute.BUNDLES]: CatalogItemType.BUNDLE,
  [RatesTypeAsRoute.SERVICES]: CatalogItemType.SERVICE,
  [RatesTypeAsRoute.PRODUCTS]: CatalogItemType.PRODUCT,
};
