import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Textarea } from '@devopsafs/react-ui-components';

import Loader from 'src/components/common/loader';

import popupStore from 'src/stores/popup-store';

import { PopupName } from 'src/constants';
import type { ICommonPopupProps } from 'src/interfaces';

const PopupConfirm = observer(({ onCloseButtonClick }: ICommonPopupProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    textareaProps,
    mainActionLabel,
    secondaryActionLabel,
    shouldClosePopupAfterMainHandler = false,
    text = '',
    title = '',
    mainActionHandler,
    secondaryActionHandler,
  } = popupStore.getPopupState(PopupName.CONFIRM);
  const [textareaValue, setTextareaValue] = useState(textareaProps?.value ?? '');

  const handleMainButtonClick = async () => {
    if (mainActionHandler) {
      setIsLoading(true);
      textareaProps?.onChange(textareaValue);
      await mainActionHandler(onCloseButtonClick);
      setIsLoading(false);
    }

    if (shouldClosePopupAfterMainHandler) {
      onCloseButtonClick();
    }
  };

  const handleSecondaryButtonClick = () => {
    secondaryActionHandler?.();
    onCloseButtonClick();
  };

  return (
    <>
      <div className="popup__header">
        <p className="popup__title">{title}</p>
        {text && <p className="popup__text">{text}</p>}
      </div>

      {textareaProps && (
        <Textarea
          className="popup__textarea"
          {...textareaProps}
          isDisabled={isLoading}
          value={textareaValue}
          onChange={setTextareaValue}
        />
      )}

      <div className="popup__buttons">
        <Button
          className="popup__button"
          label={secondaryActionLabel}
          theme="outlined"
          onClick={handleSecondaryButtonClick}
        />
        <Button
          className="popup__button"
          icon={isLoading && <Loader isInline size="small" />}
          iconPosition="center"
          isDisabled={isLoading}
          isLabelHidden={isLoading}
          label={mainActionLabel}
          theme="filled"
          onClick={handleMainButtonClick}
        />
      </div>
    </>
  );
});

export default PopupConfirm;
