import { makeAutoObservable, reaction } from 'mobx';

import socketStore from 'src/stores/socket-store';
import statisticsStore from 'src/stores/statistics-store';

import {
  APP_LOCALSTORAGE_JWT,
  APP_LOCALSTORAGE_USER,
  CONFIG_REQUIRED_PARAMS_MAPPED,
} from 'src/constants';
import { getValueFromLocalStorage } from 'src/utils';
import type { IUser } from 'src/interfaces';

class GlobalAppStore {
  init() {
    if (this.isConfigCorrect) {
      this.checkToken();
      this.checkUser();
    }
  }

  isLoading = false;
  token: string = getValueFromLocalStorage(APP_LOCALSTORAGE_JWT) ?? '';
  user: IUser | null = getValueFromLocalStorage(APP_LOCALSTORAGE_USER) ?? null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.isAuthorized,
      (isAuthorized) => {
        if (isAuthorized) {
          statisticsStore.init();
          socketStore.init(this.token);
        } else {
          socketStore.clear();
          statisticsStore.clear();
        }
      },
      { fireImmediately: true }
    );

    this.init();
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setToken(token: string) {
    this.token = token;

    if (token) {
      localStorage?.setItem(APP_LOCALSTORAGE_JWT, token);
    }
  }

  setUser(user: IUser | null) {
    this.user = user;

    if (user) {
      localStorage?.setItem(APP_LOCALSTORAGE_USER, JSON.stringify(user));
    }
  }

  removeToken() {
    localStorage?.removeItem(APP_LOCALSTORAGE_JWT);
    this.setToken('');
  }

  removeUser() {
    localStorage?.removeItem(APP_LOCALSTORAGE_USER);
    this.setUser(null);
  }

  logout() {
    this.removeToken();
    this.removeUser();
  }

  checkToken() {
    const TOKEN = process.env.REACT_APP_TOKEN;

    if (!this.token && TOKEN && process.env.NODE_ENV === 'development') {
      this.setToken(TOKEN);
    }
  }

  checkUser() {
    const USER = process.env.REACT_APP_USER;

    if (!this.user && USER && process.env.NODE_ENV === 'development') {
      this.setUser(JSON.parse(USER));
    }
  }

  get isAuthorized() {
    return !!this.token;
  }

  get isConfigCorrect() {
    return this.missingConfigVars.length === 0;
  }

  get missingConfigVars() {
    return CONFIG_REQUIRED_PARAMS_MAPPED.filter((item) => !item.value);
  }
}

export default new GlobalAppStore();
