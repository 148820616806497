import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { matchRoutes } from 'src/utils';
import type { TabProps } from './tab.props';

import './tab.scss';

const Tab = ({
  className,
  activeTextType,
  end = false,
  title,
  link,
  listAdditionalActiveRoutes,
  icon,
}: TabProps) => {
  const { pathname } = useLocation();

  const isAdditionalPathMatch = matchRoutes(pathname, listAdditionalActiveRoutes);

  return (
    <NavLink
      className={({ isActive }) =>
        classNames(
          'tab',
          className,
          (isActive || isAdditionalPathMatch) && `tab_active tab_active_${activeTextType}`
        )
      }
      to={link}
      end={end}
    >
      {icon && <span className="tab__icon">{icon}</span>}
      <span className="tab__title">{title}</span>
    </NavLink>
  );
};

export default Tab;
