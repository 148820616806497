import { formatApiToPhone } from 'src/utils';
import type { IApiContractor, IContractor } from 'src/interfaces';

export const mapApiToContractor = ({
  firstname,
  lastname,
  phone,
  ...rest
}: IApiContractor): IContractor => {
  return {
    ...rest,
    firstName: firstname,
    lastName: lastname,
    fullName: `${firstname} ${lastname}`,
    phone: formatApiToPhone(phone),
  };
};
