import { type IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import type DateRangeStore from 'src/stores/common/date-range-store';
import PagingDataStore from 'src/stores/data-store/paging-data-store';
import filtersStore from 'src/stores/filters-store';
import globalAppStore from 'src/stores/global-app-store';
import popupStore from 'src/stores/popup-store';
import socketStore from 'src/stores/socket-store';

import { mapApiToCalculation } from 'src/adapters';
import { fetchCalculationsExport, fetchSync } from 'src/api';
import {
  ApiRoute,
  AppRoute,
  CalculationStatus,
  DateFormat,
  ErrorText,
  PopupName,
} from 'src/constants';
import { formatDate, getApiFormatDateRange, getCurrentRoute, getYearWeek } from 'src/utils';
import type {
  IApiCalculation,
  ICalculation,
  ICalculationsFilter,
  IStatistics,
} from 'src/interfaces';

const MAX_LIST_COUNT_PER_PAGE = 15;
const SOCKET_LISTENER_ID = uuidv4();

class CalculationsStore {
  init() {
    socketStore.addStatisticsListener({
      id: SOCKET_LISTENER_ID,
      cb: this.updateList,
    });

    this.reactionDisposers = [
      reaction(
        () => filtersStore.dateRangeStore.dateRange,
        () => this.applyDateFilter(filtersStore.dateRangeStore)
      ),
      reaction(
        () => filtersStore.selectedCalculationStatus,
        (status) => this.applyStatusFilter(status)
      ),
      reaction(
        () => filtersStore.selectedContractor,
        (contractor) => this.applyContractor(contractor)
      ),
      reaction(
        () => [this.calculationsDataStore.filters, globalAppStore.isAuthorized] as const,
        ([filters, isAuthorized]) => {
          if (this.isMainPage) {
            if (!isAuthorized) {
              return socketStore.unsubscribeFromStatistics();
            }

            socketStore.subscribeToCalculationsStatistics(filters);
          }
        },
        { fireImmediately: true }
      ),
    ];

    void this.calculationsDataStore.load();
  }

  calculationsDataStore = new PagingDataStore<IApiCalculation, ICalculationsFilter>({
    url: ApiRoute.CALCULATIONS,
    data: [],
    pagingProps: {
      offset: 0,
      limit: MAX_LIST_COUNT_PER_PAGE,
    },
    filters: this.getStoreFilter(),
  });
  isDownloading: boolean = false;
  isSyncing: boolean = false;
  processingCalculationsIds: string[] = [];
  reactionDisposers: IReactionDisposer[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDownloading(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }

  setProcessingCalculations(processingCalculations: string[]) {
    this.processingCalculationsIds = processingCalculations;
  }

  setSyncing(isSyncing: boolean) {
    this.isSyncing = isSyncing;
  }

  addProcessingCalculation(calculationId: string) {
    this.setProcessingCalculations([...this.processingCalculationsIds, calculationId]);
  }

  removeProcessingCalculation(calculationId: string) {
    this.setProcessingCalculations(
      this.processingCalculationsIds.filter((id) => id !== calculationId)
    );
  }

  loadMore() {
    if (this.hasMore) {
      void this.calculationsDataStore.loadNextPage();
    }
  }

  applyContractor(contractor: string) {
    const { contractor: contractorFilter, ...otherFilters } = this.calculationsDataStore.filters;

    this.applyFilter({ ...otherFilters, ...(contractor && { contractor }) });
  }

  applyDateFilter(dateRangeStore: DateRangeStore) {
    const { date, ...otherFilters } = this.calculationsDataStore.filters;

    if (dateRangeStore.isEmptyDate && date) {
      this.applyFilter(otherFilters);
    }

    if (dateRangeStore.isDateValid.isValid) {
      this.applyFilter({
        ...otherFilters,
        date: getApiFormatDateRange(dateRangeStore.dateRange),
      });
    }
  }

  applyStatusFilter(status: CalculationStatus | '') {
    const { status: statusFilter, ...otherFilters } = this.calculationsDataStore.filters;

    if (!filtersStore.dateRangeStore.isDateValid.isValid) {
      if (!filtersStore.dateRangeStore.isEmptyDate) {
        filtersStore.dateRangeStore.clearDate();
      }
    }

    this.applyFilter({ ...otherFilters, ...(status && { status: [status] }) });
  }

  applyFilter(filter: ICalculationsFilter) {
    this.calculationsDataStore.setFilters(filter);
  }

  getStoreFilter() {
    return {
      ...(filtersStore.selectedContractor && { contractor: filtersStore.selectedContractor }),
      date: getApiFormatDateRange(filtersStore.dateRangeStore.dateRange),
      ...(filtersStore.selectedCalculationStatus && {
        status: [filtersStore.selectedCalculationStatus],
      }),
    };
  }

  clear() {
    this.reactionDisposers.forEach((disposer) => disposer());
    socketStore.removeStatisticsListener(SOCKET_LISTENER_ID);
    socketStore.unsubscribeFromStatistics();
    this.resetFilters();
    this.calculationsDataStore.setFilters(this.getStoreFilter(), true);
  }

  resetFilters() {
    filtersStore.resetStore();
  }

  updateList(_statistics: IStatistics, isFirstStatisticsLoad?: boolean) {
    if (isFirstStatisticsLoad) {
      return;
    }

    if (this.isMainPage) {
      void this.reload();
    }
  }

  get isLoading() {
    return this.calculationsDataStore.isLoading;
  }

  get hasMore() {
    return !this.calculationsDataStore.isAllDataLoaded;
  }

  get isMainPage() {
    const currentRoute = getCurrentRoute(window.location.pathname) as AppRoute;
    return currentRoute === AppRoute.CONTRACTORS_ACCOUNTING;
  }

  get items() {
    return this.calculationsDataStore.getData.map(mapApiToCalculation);
  }

  get itemsByDate() {
    return this.items.reduce(
      (calculations, item) => {
        const weekNumber = getYearWeek(item.date);
        const formattedDate = formatDate(item.date, DateFormat.FULL_WITH_WEEK_DAY);
        return {
          ...calculations,
          [weekNumber]: {
            ...calculations[weekNumber],
            [formattedDate]: [...(calculations[weekNumber]?.[formattedDate] ?? []), item],
          },
        };
      },
      {} as Record<string, Record<string, ICalculation[]>>
    );
  }

  async handleSyncButtonClick() {
    this.setSyncing(true);

    const { error, isSuccess } = await fetchSync();
    if (!isSuccess) {
      popupStore.showPopup(PopupName.WARN, {
        title: ErrorText.REQUEST_FAILURE,
        text: error || ErrorText.DEFAULT,
      });
    }

    this.setSyncing(false);
  }

  reload() {
    return this.calculationsDataStore.reload();
  }

  async exportCalculations() {
    if (this.isDownloading) {
      return;
    }

    this.setDownloading(true);

    const { filters } = this.calculationsDataStore;

    const { error, isSuccess } = await fetchCalculationsExport({ filter: filters });
    if (!isSuccess) {
      popupStore.showPopup(PopupName.WARN, {
        title: ErrorText.REQUEST_FAILURE,
        text: error || ErrorText.DEFAULT,
      });
    }

    this.setDownloading(false);
  }
}

export default new CalculationsStore();
