import { SyntheticEvent, useRef } from 'react';

import IconButton from 'src/components/ui/icon-button';
import { ReactComponent as Cross } from 'src/assets/icons/cross.svg';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';

import type { SelectSearchProps } from './select-search.props';

import './select-search.scss';

const SelectSearch = ({
  inputVal,
  isSearchFocus,
  setInputVal,
  setIsSearchFocus,
}: SelectSearchProps) => {
  const searchRef = useRef<HTMLInputElement>(null);

  const handleSearchChange = (evt: SyntheticEvent<HTMLInputElement>) => {
    setInputVal(evt.currentTarget.value);
  };

  const handleSearchFocus = () => {
    setIsSearchFocus(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocus(false);
  };

  const handleClearButtonClick = () => {
    setInputVal('');
  };

  const handleSearchButtonClick = () => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  return (
    <div className="select-search">
      <input
        className="select-search__input"
        ref={searchRef}
        placeholder="Search"
        type="text"
        value={inputVal}
        onBlur={handleSearchBlur}
        onChange={handleSearchChange}
        onFocus={handleSearchFocus}
      />
      {isSearchFocus ? (
        <IconButton
          ariaLabel="clear search"
          icon={<Cross className="select-search__icon select-search__icon_clear" />}
          theme="plain"
          onClick={handleClearButtonClick}
          onMouseDown={(evt) => evt.preventDefault()}
        />
      ) : (
        <IconButton
          ariaLabel="search"
          icon={<Search className="select-search__icon select-search__icon_search" />}
          theme="plain"
          onClick={handleSearchButtonClick}
        />
      )}
    </div>
  );
};

export default SelectSearch;
