import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Sidebar from 'src/components/common/sidebar';

import calculationDetailsStore from 'src/stores/calculation-details-store';

import './print-contractor-calculation-page.scss';

const PrintContractorCalculationPage = observer(() => {
  const { id } = useParams();
  const { calculation, isLoading, clearStore, loadCalculation } = calculationDetailsStore;

  useEffect(() => {
    if (id) {
      void loadCalculation(id);
    }
    return clearStore;
  }, [clearStore, id, loadCalculation]);

  useEffect(() => {
    if (!isLoading && calculation) {
      window.print();
    }
  }, [calculation, isLoading]);

  return (
    <div className="print-contractor-calculation-page" tabIndex={-1}>
      {id && <Sidebar id={id} isExternal isPrintable />}
    </div>
  );
});

export default PrintContractorCalculationPage;
