import { makeAutoObservable } from 'mobx';

import { PopupName } from 'src/constants';
import type {
  PopupConfirmState,
  PopupCreateTeamState,
  PopupWarnState,
  TGetPopupState,
} from 'src/interfaces';

type PopupState = Record<string, any>;
type IPopups = Record<PopupName, PopupState>;

class PopupStore {
  activePopup: PopupName | null = null;

  popups: IPopups = Object.values(PopupName).reduce(
    (popups, popupName) => ({
      ...popups,
      [popupName as PopupName]: this.getInitialPopupState(),
    }),
    {} as IPopups
  );

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  hidePopup(popupName: PopupName) {
    this.activePopup = null;
    this.popups[popupName] = this.getInitialPopupState();
  }

  showPopup(popupName: PopupName.CONFIRM, state: PopupConfirmState): void;
  showPopup(popupName: PopupName.CREATE_TEAM, state: PopupCreateTeamState): void;
  showPopup(popupName: PopupName.WARN, state?: PopupWarnState): void;
  showPopup(popupName: PopupName, state: PopupState = {}) {
    this.activePopup = popupName;
    this.popups[popupName] = Object.assign({ isVisible: true }, state);
  }

  getPopupState(popupName: PopupName.CONFIRM): TGetPopupState<PopupConfirmState>;
  getPopupState(popupName: PopupName.CREATE_TEAM): TGetPopupState<PopupCreateTeamState>;
  getPopupState(popupName: PopupName.WARN): TGetPopupState<PopupWarnState>;
  getPopupState(popupName: PopupName): TGetPopupState;
  getPopupState(popupName: PopupName) {
    return this.popups[popupName];
  }

  getInitialPopupState() {
    return {
      isVisible: false,
    };
  }
}

export default new PopupStore();
